import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { FixedSizeList } from 'react-window';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';

//import { UserRole } from './UserRole';
import {UserRole} from './UserRoleSelector';
import UserEditor from './UserEditor';

import { fetchUsers, deleteUser } from '../actions';

import { ROW_HEIGHT } from '../constants';

import { getUsersSortedByName } from '../selectors';

class Users extends PureComponent {

  state = {
    showUserEditor : false
  }

  constructor(props) {
    super(props);

    this.renderUserRow = this.renderUserRow.bind(this);
    this.editUser      = this.editUser.bind(this);
    this.deleteUser    = this.deleteUser.bind(this);

    this.renderUserEditorDialog = this.renderUserEditorDialog.bind(this);
    this.handleUserEditorClose  = this.handleUserEditorClose.bind(this);
  }

  componentDidMount() {
    if (!this.props.users.length) {
      this.props.fetchUsers();
    }
  }

  editUser(user_id) {
    this.setState({showUserEditor:true, user_id});
  }

  deleteUser(user_id) {
    if (window.confirm('Really delete user?')) {
      this.props.deleteUser(user_id);
    }
  }

  renderUserRow( { data, index } ) {
    const row = data[index];
    const rowClass = (index + 1) % 2 ? 'odd-user-row' : 'even-user-row';
    const isMeClass = row.user_id === this.props.session.user_id ? 'current-user-row' : '';

    return (
      <div className={classNames('user-row', rowClass, isMeClass )}>
        <div>{ row.username }</div>
        <div className='name-cell'>
          <div>{ row.name }</div>
          <div>{ row.email }</div>
        </div>
        <div className='user-role-container'>
          <UserRole user_role={row.user_role_id} />
          <span className='buttons'>
            <Button mini variant='fab' aria-label="edit user" onClick={() => this.editUser(row.user_id)} data-day={row.day} data-index={index}>
              <Icon>edit_icon</Icon>
            </Button>
            <Button color='secondary' mini variant='fab' aria-label="delete user" onClick={(e) => this.deleteUser(row.user_id)} tabIndex={-1}>
              <DeleteIcon/>
            </Button>
          </span>
        </div>
      </div>
    )
  }

  handleUserEditorClose() {
    this.setState({showUserEditor:false, user_id : undefined});
  }

  renderUserEditorDialog() {
    return this.state.showUserEditor &&
      <UserEditor
        handleUserEditorClose={this.handleUserEditorClose}
        showUserEditor={this.state.showUserEditor}
        user_id={this.state.user_id} />
    ;
  }

  render() {
    return (
      <div className='users'>
        <div className='user-actions'>
          <Button
            type='submit'
            variant = 'contained'
            color='primary'
            size="small"
            onClick={ () => this.editUser(undefined) }>New User</Button>
        </div>
        <div className='user-headers'>
          <div>Username           {/* <i className={classNames('fa', 'fa-sort', 'sort-disabled')}/> */}</div>
          <div>Name               {/* <i className={classNames('fa', 'fa-sort', 'sort-disabled')}/> */}</div>
          <div>User Role          {/* <i className={classNames('fa', 'fa-sort', 'sort-disabled')}/> */}</div>
        </div>
        <FixedSizeList
          height={800}
          itemCount={this.props.users.length}
          itemData={this.props.users}
          itemSize={ROW_HEIGHT}
          width={'100%'}
          ref={ ref => this.list = ref }
        >
          { this.renderUserRow }
        </FixedSizeList>
        { this.renderUserEditorDialog() }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users   : getUsersSortedByName(state),
    session : state.session
  }
}

export default connect(mapStateToProps, { fetchUsers, deleteUser })(Users);
