import React, { Component, Fragment } from 'react';
import {Switch, Route, Redirect} from 'react-router';

import Session from './Session';
import Password from './Password';
import AuthenticatedRoute from './AuthenticatedRoute';
import Users from './Users';
import Rewards from './Rewards';
import Status from './Status';
import Dashboard from './Dashboard';
import Report from './Report';
import Navigation from './Navigation';
import Welcome from './Welcome';
import { SubjectComponent } from './AuthenticatedComponent';

import { USER } from '../constants';

import '../styles/App.css';

import NavBar from './NavBar';

class App extends Component {

  render() {
    return (
      <Fragment>
        <NavBar/>
        <div className="App">
          <SubjectComponent>
            <Navigation />
          </SubjectComponent>

          <Switch>
            <Route exact path='/'          component={Welcome} />
            <Route exact path='/dashboard' component={AuthenticatedRoute(Dashboard)} />
            <Route exact path='/session'   component={Session} />
            <Route exact path='/password'  component={Password} />
            <Route exact path='/users'     component={AuthenticatedRoute(Users, USER.TEAMLEAD.RANK)} />
            <Route exact path='/rewards'   component={AuthenticatedRoute(Rewards)} />
            <Route exact path='/report'    component={AuthenticatedRoute(Report)} />

            <Route render={() => <Redirect to="/session"/>} />
          </Switch>

          <Status/>
        </div>
      </Fragment>
    );
  }
}

export default App;
