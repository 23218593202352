import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CONSTANTS from '../constants';

class AuthenticatedComponent extends PureComponent {

  static defaultProps = {
    exact : false,
  }

  render() {
    const {userRank, requiredRank, exact} = this.props;

    const displayChildren = exact
      ? userRank === requiredRank
      : userRank >= requiredRank;

    if (displayChildren) {
      return this.props.children;
    }
    else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  const {session} = state;
  return {
    userRank : session.rank
  }
}

const WrappedAuthenticatedComponent = connect(mapStateToProps)(AuthenticatedComponent);

export const AdminComponent = (props) => {
  return <WrappedAuthenticatedComponent requiredRank={CONSTANTS.USER.ADMIN.RANK} {...props} />
}

export const TeamLeadComponent = (props) => {
  return <WrappedAuthenticatedComponent requiredRank={CONSTANTS.USER.TEAMLEAD.RANK} {...props} />
}

export const FamilyMemberComponent = (props) => {
  return <WrappedAuthenticatedComponent requiredRank={CONSTANTS.USER.FAMILYMEMBER.RANK} {...props} />
}

export const TeamMemberComponent = (props) => {
  return <WrappedAuthenticatedComponent requiredRank={CONSTANTS.USER.TEAMMEMBER.RANK} {...props} />
}

export const SubjectComponent = (props) => {
  return <WrappedAuthenticatedComponent requiredRank={CONSTANTS.USER.SUBJECT.RANK} {...props} />
}
