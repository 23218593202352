import { createSelector } from 'reselect';

import { createComparator } from '../utilities';
import { USER } from '../constants';

export const getUsers = (state) => state.users;

const nameComparator    = createComparator('name', 'asc');

export const getSubjects = createSelector(
  [getUsers],
  (users) => {
    return Object.values(users).filter( user => user.user_role_id === USER.SUBJECT.ID )
  }
)

export const getSubjectsSortedByName = createSelector(
  [getSubjects],
  (subjects) => {
    const sorted = subjects.sort(nameComparator);
    return sorted;
  }
)

export const getUsersSortedByName = createSelector(
  [getUsers],
  (users) => {
    return Object.values(users).sort(nameComparator)
  }
)
