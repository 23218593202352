import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import { getSubjectsSortedByName } from '../selectors';

const styles = theme => ({
  formControl: {
    //margin: theme.spacing.unit,
    //minWidth: 120,
    width : '100%',
    //margin : '8px'
    //display : 'grid',
    //gridTemplateColumns : '1fr 1fr'
  },
  addBehaviorButton : {
    minWidth : '120px', //102px is all that's necessary
    marginLeft : '5px'
  }
});

class Subjects extends PureComponent {

  static defaultProps = {
    selectable : true
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    e.preventDefault(); e.stopPropagation();

    const newValue = parseInt(e.target.value, 10);

    this.props.selectSubject( newValue );
  }

  render() {

    const { subjects, selected, classes, selectable, users } = this.props;

    if (subjects.length === 0) {
      return null;
    }
    else if (subjects.length === 1) {
      return <span className='subject-name'>{subjects[0].name}</span>;
    }
    else if (!selectable && selected) {
      return <span className='subject-name'>{users[selected].name}</span>;
    }
    else {

      return (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="subject_id">Selected Child</InputLabel>
          <NativeSelect
            native='true'
            value={selected}
            onChange={this.onChange}
            inputProps={{
              name: 'subject',
              id: 'subject_id',
            }}
          >
            { subjects.map( subject => (
              <option value={subject.user_id} key={subject.user_id}>{subject.name}</option>
            ))}
          </NativeSelect>
        </FormControl>
      );
    }
  }
}

const mapStateToProps = (state, {selected}) => {
  return {
    subjects : getSubjectsSortedByName(state),
    selected : selected || state.subjects.selected,
    users    : state.users,
  }
};

export default connect(mapStateToProps)(
  withStyles(styles)(Subjects)
);
