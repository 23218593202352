import { call, all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  LOGIN_USER,
  START_LOGOUT_USER,
  GET_SESSION,
} from '../actions/types';

import { setSession, setStatus, loggedoutUser } from '../actions';

import { STATUS} from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( LOGIN_USER,        loginUser),
    takeLatest( START_LOGOUT_USER, logoutUser),
    takeLatest( GET_SESSION,       getSession),
  ])
}

function* loginUser(action) {
  const { username, password } = action.payload;
  try {
    const res = yield call(() => axios.post(`/api/session`, {username, password}));
    yield put( setSession(res.data))
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not log in'}));
    yield put( setSession({invalid : true}) );
  }

}

function* logoutUser(action) {
  try {
    yield call(() => axios.delete(`/api/session`));
    yield put( loggedoutUser() );
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not log out'}));
  }
}

function* getSession(action) {
  try {
    const res = yield call( () => axios.get('/api/session') );
    yield put( setSession(res.data) );
  }
  catch(e) {
    yield(put(setSession({invalid : true})));
  }
}
