import {
  REPLACE_BEHAVIORS_IN_STORE,
  ADD_BEHAVIORS_TO_STORE,
  LOGOUT_USER,
  UPDATE_BEHAVIOR_IN_STORE,
  DELETE_BEHAVIOR_FROM_STORE
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case REPLACE_BEHAVIORS_IN_STORE :
      return action.payload;
    case ADD_BEHAVIORS_TO_STORE :
      const behaviors = action.payload;
      return { ...state, ...behaviors };
    case UPDATE_BEHAVIOR_IN_STORE :
      const behavior = action.payload;
      return { ...state, [behavior.behavior_id] : behavior };
    case DELETE_BEHAVIOR_FROM_STORE : {
      const behavior_id = action.payload;
      let newState = {...state};
      delete newState[behavior_id];
      return newState;
    }
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
