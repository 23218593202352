import {
  REPLACE_RECEIPTS_IN_STORE,
  ADD_RECEIPTS_TO_STORE,
  DELETE_RECEIPT_FROM_STORE,
  UPDATE_RECEIPT_IN_STORE,
  LOGOUT_USER
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case REPLACE_RECEIPTS_IN_STORE :
      return action.payload;
    case ADD_RECEIPTS_TO_STORE :
      const receipts = action.payload;
      return { ...state, ...receipts };
    case DELETE_RECEIPT_FROM_STORE : {
      const { receipt_id } = action.payload;
      const newState = {...state};
      delete newState[receipt_id];
      return newState;
    }
    case UPDATE_RECEIPT_IN_STORE : {
      const receipt = action.payload;
      return {...state, [receipt.receipt_id] : receipt };
    }
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
