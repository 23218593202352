import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';

import {
  SELECT_SUBJECT,
  CREATE_RECEIPT,
  FETCH_RECEIPTS,
  DELETE_RECEIPT,
  EDIT_RECEIPT
} from '../actions/types';

import {
  replaceReceiptsInStore,
  addReceiptToStore,
  setStatus,
  deleteReceiptFromStore,
  updateReceiptInStore,
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( CREATE_RECEIPT,  createReceipt),
    takeLatest( DELETE_RECEIPT,  deleteReceipt),
    takeLatest( EDIT_RECEIPT,    editReceipt),
  ])
}


function* createReceipt(action) {

  try {

    const state = yield select();
    const nextReceiptID = Math.max(0, ...Object.keys(state.receipts)) + 1;
    const receipt       = { ...action.payload, receipt_id : nextReceiptID };

    yield put( addReceiptToStore(receipt) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added receipt'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not add receipt'}));
  }

}

function* deleteReceipt(action) {

  try {
    const { receipt_id } = action.payload;

    yield put( deleteReceiptFromStore(receipt_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted receipt'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete receipt'}));
  }

}

function* editReceipt(action) {

  try {
    const receipt = action.payload;

    yield put( updateReceiptInStore(receipt) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated receipt'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not update receipt'}));
  }

}
