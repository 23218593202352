import { SET_STATUS, CLEAR_STATUS } from './types';

export * from './session';
export * from './user';
export * from './behavior';
export * from './category';
export * from './point';
export * from './receipt';
export * from './reward';
export * from './team';
export * from './subjects';

export const setStatus = (status) => {
  return {
    type    : SET_STATUS,
    payload : status,
  }
}

export const clearStatus = (status) => {
  return {
    type    : CLEAR_STATUS,
  }
}
