import {
  SET_SESSION,
  LOGOUT_USER
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case SET_SESSION :
      return action.payload;
    case LOGOUT_USER :
      return { invalid : true };
    default :
      return state;
  }
};
