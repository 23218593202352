import { combineReducers } from 'redux';

import session    from './session';
import users      from './users';
import status     from './status';
import behaviors  from './behavior';
import categories from './category';
import points     from './point';
import receipts   from './receipt';
import rewards    from './reward';
import team       from './team';
import subjects   from './subjects';

export default combineReducers({
  session,
  users,
  status,
  behaviors,
  categories,
  points,
  receipts,
  rewards,
  team,
  subjects
});
