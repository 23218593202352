import { call, all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  SELECT_SUBJECT,
  CREATE_REWARD,
  FETCH_REWARDS,
  DELETE_REWARD,
  EDIT_REWARD
} from '../actions/types';

import {
  replaceRewardsInStore,
  addRewardsToStore,
  addRewardToStore,
  setStatus,
  deleteRewardFromStore,
  updateRewardInStore,
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( SELECT_SUBJECT, fetchRewards),
    takeLatest( FETCH_REWARDS,  fetchRewards),
    takeLatest( CREATE_REWARD,  createReward),
    takeLatest( DELETE_REWARD,  deleteReward),
    takeLatest( EDIT_REWARD,    editReward),
  ])
}


function* fetchRewards(action) {

  try {
    const { subject_id } = action.payload;
    const res = yield call( () => axios.get(`/api/user/${subject_id}/rewards`) );

    yield put( addRewardsToStore(res.data) );
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not fetch rewards'}));
  }

}

function* createReward(action) {

  try {
    const { subject_id, description, points } = action.payload;
    const res = yield call( () => axios.post(`/api/reward`, { subject_id, description, points } ) );

    yield put( addRewardToStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added reward'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not add reward'}));
  }

}

function* deleteReward(action) {

  try {
    const { reward_id } = action.payload;
    yield call( () => axios.delete(`/api/reward/${reward_id}`) );

    yield put( deleteRewardFromStore(reward_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted reward'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete reward'}));
  }

}

function* editReward(action) {

  try {
    const { reward_id, subject_id, description, points } = action.payload;
    const res = yield call( () => axios.patch(`/api/reward/${reward_id}`, { subject_id, description, points } ));

    yield put( updateRewardInStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated reward'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not update reward'}));
  }

}
