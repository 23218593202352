import {
  FETCH_REWARDS,
  REPLACE_REWARDS_IN_STORE,
  CREATE_REWARD,
  ADD_REWARDS_TO_STORE,
  DELETE_REWARD,
  DELETE_REWARD_FROM_STORE,
  EDIT_REWARD,
  UPDATE_REWARD_IN_STORE,
} from './types';

export const fetchRewards = (subject_id) => {
  return {
    type    : FETCH_REWARDS,
    payload : { subject_id }
  }
}

export const replaceRewardsInStore = (rewards) => {
  return {
    type    : REPLACE_REWARDS_IN_STORE,
    payload : rewards
  }
}

export const createReward = ({ subject_id, description, points }) => {
  return {
    type    : CREATE_REWARD,
    payload : { subject_id, description, points }
  }
}

export const addRewardsToStore = (reward) => {
  return {
    type    : ADD_REWARDS_TO_STORE,
    payload : reward
  }
}

export const addRewardToStore = (reward) => {
  return {
    type    : ADD_REWARDS_TO_STORE,
    payload : { [reward.reward_id] : reward }
  }
}

export const deleteReward = (reward_id) => {
  return {
    type    : DELETE_REWARD,
    payload : { reward_id }
  }
}

export const deleteRewardFromStore = (reward_id) => {
  return {
    type    : DELETE_REWARD_FROM_STORE,
    payload : { reward_id }
  }
}

export const editReward = (reward_id, {description, points}) => {
  return {
    type    : EDIT_REWARD,
    payload : { reward_id, description, points }
  }
}

export const updateRewardInStore = (reward) => {
  return {
    type    : UPDATE_REWARD_IN_STORE,
    payload : reward
  }
}
