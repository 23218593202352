import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import { createComparator, comparatorSet } from '../utilities';

import { USER } from '../constants';

const roleComparator = comparatorSet([
  createComparator('RANK', 'desc', 'numeric'),
  createComparator('NAME', 'asc'),
]);

const styles = theme => ({
  formControl: {
    width : '100%',
  },
});

const ROLE_MAPPING = Object.values(USER).reduce( (roles, user) => {
  roles[user.ID] = user.NAME;
  return roles;
}, {} );

export const UserRole = ({user_role}) => ROLE_MAPPING[user_role];

class UnwrappedUserRoleSelector extends PureComponent {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (this.props.handleChange) {
      this.props.handleChange(e);
    }
  }

  render() {
    const { classes, rank } = this.props;
    
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="user_role_id">User Role</InputLabel>
        <NativeSelect
          native='true'
          value={this.props.value}
          onChange={this.handleChange}
          inputProps={{
            name: 'user_role_id',
            id: 'user_role_id',
          }}
        >
          { Object.values(USER)
              .filter( u => u.RANK <= rank )
              .sort(roleComparator)
              .map( u => <option key={u.ID} value={u.ID}>{u.NAME}</option> )
          }
        </NativeSelect>
      </FormControl>
    )
  }
}

const mapStateToProps = ({session}) => {
  return {session};
}

export default connect(mapStateToProps)(
  withStyles(styles)(UnwrappedUserRoleSelector)
);
