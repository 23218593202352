import {
  FETCH_POINTS,
  REPLACE_POINTS_IN_STORE,
  CREATE_POINT,
  ADD_POINTS_TO_STORE,
  DELETE_POINT,
  DELETE_POINT_FROM_STORE,
  EDIT_POINT,
  UPDATE_POINT_IN_STORE,
} from './types';

export const fetchPoints = (subject_id) => {
  return {
    type    : FETCH_POINTS,
    payload : { subject_id }
  }
}

export const replacePointsInStore = (points) => {
  return {
    type    : REPLACE_POINTS_IN_STORE,
    payload : points
  }
}

export const createPoint = ({ subject_id, behavior_id }) => {
  return {
    type    : CREATE_POINT,
    payload : { subject_id, behavior_id }
  }
}

export const addPointsToStore = (points) => {
  return {
    type    : ADD_POINTS_TO_STORE,
    payload : points
  }
}

export const addPointToStore = (point) => {
  return {
    type    : ADD_POINTS_TO_STORE,
    payload : { [point.point_id] : point }
  }
}

export const deletePoint = (point_id) => {
  return {
    type    : DELETE_POINT,
    payload : { point_id }
  }
}

export const deletePointFromStore = (point_id) => {
  return {
    type    : DELETE_POINT_FROM_STORE,
    payload : { point_id }
  }
}

export const editPoint = (point_id, {notes}) => {
  return {
    type    : EDIT_POINT,
    payload : { point_id, notes }
  }
}

export const updatePointInStore = (point) => {
  return {
    type    : UPDATE_POINT_IN_STORE,
    payload : point
  }
}
