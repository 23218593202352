import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


import { withStyles } from '@material-ui/core/styles';

import UserRoleSelector from './UserRoleSelector';
import { TeamLeadComponent } from './AuthenticatedComponent';

import { createUser, editUser } from '../actions';
import { getSubjectsSortedByName } from '../selectors';

import { USER } from '../constants';

const styles = theme => ({
  textField: {
    marginLeft: 0,//theme.spacing.unit,
    marginRight: 0,//theme.spacing.unit,
    width: '100%',
    marginTop : 0,
  },
  dateTimeField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    marginTop : '16px',
    marginBottom : '16px'
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  dialogContent: {
    width : 300,
    marginLeft : 'auto',
    marginRight : 'auto',
    marginTop : 0,
    paddingBottom : 0,
    paddingRight : 8,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    textAlign : 'center',
  },
  dialogActions : {
    display : 'flex',
    justifyContent : 'space-between',
  },
  formControl: {
    width : '100%',
  },
});

class UserEditor extends PureComponent {

  static propTypes = {
    classes  : PropTypes.object.isRequired,
  }

  state = {

    user : {
      username       : this.props.user.username || '',
      user_role_id   : this.props.user.user_role_id || '',
      password       : '',
      name           : this.props.user.name || '',
      requires_reset : this.props.user.requires_reset || 0,
      email          : this.props.user.email || '',
      subject_ids    : this.props.user.subject_ids || [],
    },

    subjectsMap    : this.props.user.subjects.reduce( (ids, id) => {
      ids[id] = id;
      return ids;
    }, {}),

    passwordA : '',
    passwordB : '',
    error : false
  }

  constructor(props) {
    super(props);

    this.nextSubjectKey       = -1;

    this.changeValue        = this.changeValue.bind(this);
    this.savePassword       = this.savePassword.bind(this);
    this.challengePassword  = this.challengePassword.bind(this);
    this.canSubmit          = this.canSubmit.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
  }

  nextKey() {
    return this.nextSubjectKey--;
  }

  changeValue(e) {
    return this.changeUserValue(e.target.id, e.target.value);
  }

  changeUserValue(key, value) {
    if (key === 'user_role_id') {
      value = parseInt(value, 10);
    }
    this.setState( {user : { ...this.state.user, [key] : value } });
  }

  toggleSubject(subject_id) {
    let subjectsMap = {...this.state.subjectsMap};
    subjectsMap[subject_id] = !subjectsMap[subject_id];

    const subject_ids = Object.keys(subjectsMap)
      .filter(id => subjectsMap[id])
      .map(id => parseInt(id, 10) );

    this.setState({
      user : { ...this.state.user, subject_ids },
      subjectsMap
    });

  }

  savePassword(e) {
    const id = e.target.id;
    const value = e.target.value;
    this.setState({[id] : value}, this.challengePassword);
  }

  challengePassword() {
    if (this.props.user_id || this.state.passwordB.length){
      if (this.state.passwordA !== this.state.passwordB) {
        this.setState({error : true});
        this.changeUserValue('password', undefined);
      }
      else {
        this.setState({error : false});
        this.changeUserValue('password', this.state.passwordA)
      }
    }
  }

  userObj() {
    // if we don't have a user id, then we want to filter down to fields
    // which have changed. If we do not have a user_id, we check everything
    return this.props.user_id
      ? Object.keys(this.state.user).reduce( (user, field) => {
          if (field === 'password') {
            if (this.state.user.password !== undefined && this.state.user.password.length) {
              user[field] = this.state.user[field];
            }
          }
          else if (this.state.user[field] !== this.props.user[field]) {
            user[field] = this.state.user[field];
          }
          return user
        }, {})
      : this.state.user;
  }

  canSubmit() {

    const userObj = this.userObj();

    return Object.keys(userObj).reduce( (canSubmit, field) => {
      return canSubmit && (!!String(userObj[field]).length || field === 'subject_ids' || field === 'email');
    }, !this.state.error);
  }

  handleSubmit(e) {
    e.preventDefault(); e.stopPropagation();

    const userObj = this.userObj();

    if (this.props.user_id) {
      this.props.editUser( this.props.user_id, userObj );
    }
    else {
      this.props.createUser( userObj )
    }

    if (this.props.saveCallback) {
      this.props.saveCallback();
    }

    this.props.handleUserEditorClose();
  }

  renderSubjectSelector(user_role_id) {

    if (user_role_id !== USER.TEAMMEMBER.ID) { return null }

    return this.props.subjects
      .filter(subject => subject.user_id !== this.props.user_id)
      .map( subject => (
      <div key={subject.user_id}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!this.state.subjectsMap[subject.user_id]}
              onChange={() => this.toggleSubject(subject.user_id)}
              value={`subject.user_id`}
              color="primary"
            />
          }
          label={subject.name}
          />
      </div>
    ));
  }

  render() {

    const { classes } = this.props;
    const { name, username, email, user_role_id, requires_reset } = this.state.user;

    return (
      <Dialog
        onClose={this.props.handleUserEditorClose}
        aria-labelledby="category-editor-title"
        open={true}
        scroll='paper'>
        <form>
          <DialogTitle id="category-editor-title">Edit User</DialogTitle>
          <DialogContent className={classes.dialogContent}>

            <div>
              <TextField
                id="name"
                label="Name"
                //placeholder="Placeholder"
                className={classes.textField}
                value={name}
                margin="normal"
                onChange={ this.changeValue }
              />
            </div>
            {!this.props.user_id && <div>
              <TextField
                id="username"
                label="Username"
                //placeholder="Placeholder"
                className={classes.textField}
                value={username}
                margin="normal"
                onChange={ this.changeValue }
              />
            </div>}
            <div>
              <TextField
                id="email"
                label="email"
                className={classes.textField}
                value={email}
                margin="normal"
                onChange={ this.changeValue }
              />
            </div>
            <TeamLeadComponent>
              <div>
                <UserRoleSelector
                  value={ user_role_id }
                  handleChange={ this.changeValue }
                  rank={ this.props.session.rank }
                />
              </div>
              { this.renderSubjectSelector(user_role_id) }
            </TeamLeadComponent>
            <div>
              <TextField
                id="passwordA"
                label="Password"
                type='password'
                placeholder="New password"
                className={classes.textField}
                margin="normal"
                onChange={ this.savePassword }
                value={this.state.passwordA}
                autoComplete="off"
              />
            </div>
            <div>
              <TextField
                error={this.state.error}
                id="passwordB"
                label="Confirm Password"
                placeholder="Confirm password"
                type='password'
                helperText={ this.state.error ? 'passwords do not match' : undefined }
                //placeholder="Placeholder"
                className={classes.textField}
                margin="normal"
                onChange={ this.savePassword }
                value={this.state.passwordB}
                autoComplete="off"
              />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="requires_reset">Requires Reset</InputLabel>
                <NativeSelect
                  native='true'
                  value={requires_reset}
                  onChange={this.changeValue}
                  inputProps={{
                    name: 'requires_reset',
                    id  : 'requires_reset',
                  }}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </NativeSelect>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              type='button'
              variant = 'contained'
              color='secondary'
              size="small"
              onClick={this.props.handleUserEditorClose}>Cancel</Button>
            <Button
              type='submit'
              disabled={!this.canSubmit()}
              variant = 'contained'
              color='primary'
              size="small"
              onClick={this.handleSubmit}>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => {

  const user = props.user_id
    ? state.users[props.user_id]
    : { requires_reset : 1, user_role_id : USER.TEAMMEMBER.ID, subjects : [] }

  return {
    user,
    users : state.users,
    subjects : getSubjectsSortedByName(state),
    session : state.session,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, { createUser, editUser })(UserEditor)
);
