import {
  SELECT_CATEGORY,
  REPLACE_CATEGORIES_IN_STORE,

  CREATE_CATEGORY,
  ADD_CATEGORY_TO_STORE,

  EDIT_CATEGORY,
  UPDATE_CATEGORY_IN_STORE,
  UPDATE_CATEGORIES,

  DELETE_CATEGORY,
  DELETE_CATEGORY_FROM_STORE
} from './types';

export const selectCategory = (category_id) => {
  return {
    type    : SELECT_CATEGORY,
    payload : { category_id }
  }
}

export const replaceCategoriesInStore = (categories) => {
  return {
    type    : REPLACE_CATEGORIES_IN_STORE,
    payload : categories
  }
}

export const createCategory = (category) => {
  return {
    type    : CREATE_CATEGORY,
    payload : category
  }
}

export const addCategoryToStore = (category) => {
  return {
    type    : ADD_CATEGORY_TO_STORE,
    payload : category
  }
}

export const editCategory = (category) => {
  return {
    type    : EDIT_CATEGORY,
    payload : category
  }
}

export const updateCategories = (categories) => {
  return {
    type    : UPDATE_CATEGORIES,
    payload : categories
  }
}

export const updateCategoryInStore = (category) => {
  return {
    type    : UPDATE_CATEGORY_IN_STORE,
    payload : category
  }
}

export const deleteCategory = (category_id) => {
  return {
    type    : DELETE_CATEGORY,
    payload : category_id
  }
}

export const deleteCategoryFromStore = (category_id) => {
  return {
    type    : DELETE_CATEGORY_FROM_STORE,
    payload : category_id
  }
}
