import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import FormattedPoints from './FormattedPoints';
import PointEditor from './PointEditor';
import PointTotals from './PointTotals';
import Subjects from './Subjects';
import { TeamMemberComponent } from './AuthenticatedComponent';

import { getSortedPointsBySubject, getPointTotals } from '../selectors';

import { DAY_FORMAT } from '../constants';

import { deletePoint } from '../actions';
import { createComparator } from '../utilities';

const dateComparator = createComparator('date', 'desc');

const DailyReport = ({points, editPoint, deletePoint}) => {
  return (
    !points.length
      ? <div className='no-points-container'>No behaviors recorded</div>
      :
        <div className='points-container'>
          <div className='point-row point-header'>
            <div className='point-item'>Time</div>
            <div className='point-item'>Behavior</div>
            <div className='point-item'>Points</div>
            <div className='point-item'>Notes</div>
          </div>
          { points.map( point => {
            return (
              <div className='point-row' key={point.point_id}>
                <div className='point-item point-time' >{ moment(`${point.date} ${point.time}`).format('h:mm a') }</div>
                <div className='point-item point-behavior' >{ point.behavior }</div>
                <div className='point-item point-points' ><FormattedPoints points={point.points}/></div>
                <div className='point-item point-notes'>
                  <span>{ point.notes }</span>
                  <TeamMemberComponent>
                    <span className='buttons'>
                      <Button mini variant='fab' aria-label="edit points" onClick={ () => editPoint(point.point_id) } tabIndex={-1}>
                        <Icon>edit_icon</Icon>
                      </Button>
                      <Button color='secondary' mini variant='fab' aria-label="delete points" onClick={ () => deletePoint(point.point_id) } tabIndex={-1}>
                        <DeleteIcon/>
                      </Button>
                    </span>
                  </TeamMemberComponent>
                </div>
              </div>
            )
          })}
        </div>
  )
}

class Report extends PureComponent {

  state = {
    date          : moment().format(DAY_FORMAT),
    expandedDates : {},
  }

  constructor(props) {
    super(props);

    this.toggleExpanded   = this.toggleExpanded.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.editPoint        = this.editPoint.bind(this);
    this.deletePoint      = this.deletePoint.bind(this);

    this.handlePointEditorClose = this.handlePointEditorClose.bind(this);
  }

  toggleExpanded(e) {
    const date = e.currentTarget.getAttribute('data-date');
    //const index = e.currentTarget.getAttribute('data-index');
    this.setState( {expandedDates : {...this.state.expandedDates, [date] : !this.state.expandedDates[date]}} );
  }

  handleDateChange(date) {
    this.setState( { date : date.format(DAY_FORMAT) } );
  }

  handlePointEditorClose() {
    this.setState(
      {
        point_id : undefined,
        showPointEditor:false
      }
    );
  }

  editPoint(point_id) {
    this.setState({
      point_id,
      showPointEditor : true,
    })
  }

  deletePoint(point_id) {
    if (window.confirm('Really delete point?')) {
      this.props.deletePoint(point_id);
    }
  }

  render() {
    const { expandedDates } = this.state;
    const totals = this.props.totals;
    
    const sortedDates = Object.keys(totals).map( date => ({ date }) ).sort(dateComparator).map(d => d.date);

    return (
      <Fragment>
        <Subjects />
        <PointTotals />
        <div className='report-point-totals-container'>
          <div className='point-row point-header'>
            <div className='point-item'>Date</div>
            <div className='point-item'>Total</div>
          </div>
          { sortedDates.map( (date, index) => (
            <div className='report-row-container' key={date}>
              <div className='point-row'>
                <div className='point-item point-date'>
                  <IconButton color="inherit" aria-label="toggle-expand" onClick={this.toggleExpanded} data-date={date} data-index={index}>
                    <Icon className={classNames('fa', expandedDates[date] ? 'fa-chevron-down' : 'fa-chevron-right')}/>
                  </IconButton>
                  { date }
                </div>
                <div className='point-item point-total'>{ totals[date] }</div>
              </div>
              { expandedDates[date] && <DailyReport
                points={this.props.points.filter( p => p.date === date)}
                editPoint={(point_id) => this.editPoint(point_id)}
                deletePoint={(point_id) => this.deletePoint(point_id)}
              /> }
            </div>
          ))}
          { this.state.showPointEditor && <PointEditor
            showPointEditor={ this.state.showPointEditor }
            handlePointEditorClose= { this.handlePointEditorClose }
            point_id={this.state.point_id}
          /> }
        </div>
      </Fragment>
    );

  }
}

const mapStateToProps = (state) => {
  const points = getSortedPointsBySubject(state)(state.subjects.selected);
  return {
    points,
    totals : getPointTotals(points),
  }
};

export default connect(mapStateToProps, { deletePoint })(Report);
