import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import { createComparator } from '../utilities';

export const getRewards = (state) => state.rewards;

const nameComparator    = createComparator('description', 'asc');
const pointComparator   = createComparator('points', 'asc', 'numeric');

const getRewardsSortedByPoints = createSelector(
  [getRewards],
  (rewards) => {
    return Object.values(rewards).sort(pointComparator);
  }
)

export const getSortedRewardsBySubject = createSelector(
  [getRewardsSortedByPoints],
  rewards => {
    return memoize( subject_id => {
      return rewards
        .filter( rewards => rewards.subject_id === subject_id )
    })
  }
);
