import {
  SET_STATUS,
  CLEAR_STATUS,
  LOGOUT_USER
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case SET_STATUS :
      return action.payload;
    case CLEAR_STATUS :
      return INITIAL;
    case LOGOUT_USER :
      return INITIAL
    default :
      return state;
  }
};
