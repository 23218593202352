import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import SubjectSelector from './SubjectSelector';

import { createBehavior, editBehavior } from '../actions';
import { getCategoriesSortedByDescription } from '../selectors';

const styles = theme => ({
  textField: {
    marginLeft: 0,//theme.spacing.unit,
    marginRight: 0,//theme.spacing.unit,
    width: '100%',
    marginTop : 0,
  },
  dateTimeField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    marginTop : '16px',
    marginBottom : '16px'
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  dialogContent: {
    width : 300,
    marginLeft : 'auto',
    marginRight : 'auto',
    marginTop : 0,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    textAlign : 'center',
  },
  dialogActions : {
    display : 'flex',
    justifyContent : 'space-between'
  },
  formControl: {
    width : '100%',
  },
});

const FIELDS = ['subject_id', 'behavior', 'points', 'visible'];

class BehaviorEditor extends PureComponent {

  state = {
    behavior : {
      subject_id  : this.props.behavior.subject_id || '',
      behavior    : this.props.behavior.behavior || '',
      description : this.props.behavior.description || '',
      points      : this.props.behavior.points || 0,
      color       : this.props.behavior.color || '',
      visible     : this.props.behavior.visible ? this.props.behavior.visible : 1,
    },

    categoriesMap    : this.props.behavior.category_ids.reduce( (ids, id) => {
      ids[id] = id;
      return ids;
    }, {}),

  }

  constructor(props) {
    super(props);
    this.changeValue   = this.changeValue.bind(this);
    this.canSubmit     = this.canSubmit.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
  }

  changeValue(e) {
    return this.changeBehaviorValue(e.target.id, e.target.value);
  }

  changeBehaviorValue(key, value) {
    this.setState( {behavior : { ...this.state.behavior, [key] : value } });
  }

  handleSubmit(e) {
    e.preventDefault(); e.stopPropagation();

    if (this.props.behavior_id) {
      this.props.editBehavior( this.props.behavior_id, this.state.behavior );
    }
    else {
      this.props.createBehavior( this.state.behavior )
    }

    if (this.props.saveCallback) {
      this.props.saveCallback();
    }

    this.props.handleEditorClose();
  }

  canSubmit() {
    return FIELDS.reduce( (canSubmit, field) => {
      return canSubmit && (!!String(this.state.behavior[field]).length || field === 'category_ids')
    }, true);
  }

  renderTextField({id, label, value, rows = 1, type = 'text'}) {
    return (
      <TextField
        id={id}
        label={label}
        className={this.props.classes.textField}
        type={type}
        value={value}
        margin="normal"
        onChange={ this.changeValue }
        multiline={rows > 1}
        rows={rows}
      />
    )
  }

  toggleCategory(category_id) {
    let categoriesMap = {...this.state.categoriesMap};
    categoriesMap[category_id] = !categoriesMap[category_id];

    const category_ids = Object.keys(categoriesMap)
      .filter(id => categoriesMap[id])
      .map(id => parseInt(id, 10) );

    this.setState({
      behavior : { ...this.state.behavior, category_ids },
      categoriesMap
    });

  }

  renderCategorySelector() {

    return this.props.categories
      .map( category => (
      <div key={category.category_id}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!this.state.categoriesMap[category.category_id]}
              onChange={() => this.toggleCategory(category.category_id)}
              value={`category.category_id`}
              color="primary"
            />
          }
          label={category.description}
          />
      </div>
    ));
  }

  render() {

    const { classes } = this.props;
    let { subject_id, behavior, description, points, color, visible } = this.state.behavior;

    return (
      <Dialog onClose={this.props.handleEditorClose} aria-labelledby="behavior-editor-title" open={this.props.showEditor}>
        <form>
          <DialogTitle id="behavior-editor-title">Edit Behavior</DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <div>
              <SubjectSelector
                selected={subject_id}
                selectable={false}
                selectSubject={(subject_id) => this.changeBehaviorValue('subject_id', subject_id)}
              />
            </div>
            <div>
              { this.renderTextField({id : 'behavior', label : 'Behavior', value : behavior}) }
            </div>
            <div>
              { this.renderTextField({id : 'description', label : 'Description', value : description, rows : 3}) }
            </div>
            <div>
              { this.renderTextField({id : 'points', label : 'Points', value : points, type : 'number'}) }
            </div>
            { this.renderCategorySelector() }
            <div>
              { this.renderTextField({id : 'color', label : 'Color', value : color}) }
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">Selected Subject</InputLabel>
                <NativeSelect
                  native='true'
                  value={visible}
                  onChange={this.changeValue}
                  inputProps={{
                    name: 'visible',
                    id  : 'visible',
                  }}
                >
                  <option value={1}>Visible</option>
                  <option value={0}>Not visible</option>
                </NativeSelect>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              type='button'
              variant = 'contained'
              color='secondary'
              size="small"
              onClick={this.props.handleEditorClose}>Cancel</Button>
            <Button
              type='submit'
              disabled={!this.canSubmit()}
              variant = 'contained'
              color='primary'
              size="small"
              onClick={this.handleSubmit}>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => {

  const behavior = props.behavior_id
    ? state.behaviors[props.behavior_id]
    : { category_ids : [], subject_id : state.subjects.selected }

  return {
    behavior,
    categories : getCategoriesSortedByDescription(state)
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, { createBehavior, editBehavior })(BehaviorEditor)
);
