export const LOGIN_USER             = 'LOGIN_USER';
export const START_LOGOUT_USER      = 'START_LOGOUT_USER';
export const LOGOUT_USER            = 'LOGOUT_USER';
export const CREATE_USER            = 'CREATE_USER';
export const EDIT_USER              = 'EDIT_USER';
export const UPDATE_USER_IN_STORE   = 'UPDATE_USER_IN_STORE';
export const FETCH_USERS            = 'FETCH_USERS';
export const REPLACE_USERS_IN_STORE = 'REPLACE_USERS_IN_STORE';
export const DELETE_USER            = 'DELETE_USER';
export const DELETE_USER_FROM_STORE = 'DELETE_USER_FROM_STORE';

export const SET_SESSION  = 'SET_SESSION';
export const GET_SESSION  = 'GET_SESSION';

export const SET_STATUS   = 'SET_STATUS';
export const CLEAR_STATUS = 'CLEAR_STATUS';

export const FETCH_BEHAVIORS                        = 'FETCH_BEHAVIORS';
export const REPLACE_BEHAVIORS_IN_STORE             = 'REPLACE_BEHAVIORS_IN_STORE';
export const ADD_BEHAVIORS_TO_STORE                 = 'ADD_BEHAVIORS_TO_STORE';
export const FETCH_BEHAVIORS_IN_CATEGORY            = 'FETCH_BEHAVIORS_IN_CATEGORY';
export const DELETE_BEHAVIOR_FROM_CATEGORY          = 'DELETE_BEHAVIOR_FROM_CATEGORY';
export const DELETE_BEHAVIOR_FROM_CATEGORY_IN_STORE = 'DELETE_BEHAVIOR_FROM_CATEGORY_IN_STORE';
export const CREATE_BEHAVIOR                        = 'CREATE_BEHAVIOR';
export const EDIT_BEHAVIOR                          = 'EDIT_BEHAVIOR';
export const UPDATE_BEHAVIOR_IN_STORE               = 'UPDATE_BEHAVIOR_IN_STORE';
export const DELETE_BEHAVIOR                        = 'DELETE_BEHAVIOR';
export const DELETE_BEHAVIOR_FROM_STORE             = 'DELETE_BEHAVIOR_FROM_STORE';

export const SELECT_CATEGORY             = 'SELECT_CATEGORY';
export const FETCH_CATEGORIES            = 'FETCH_CATEGORIES';
export const REPLACE_CATEGORIES_IN_STORE = 'REPLACE_CATEGORIES_IN_STORE';
export const CREATE_CATEGORY             = 'CREATE_CATEGORY';
export const ADD_CATEGORY_TO_STORE       = 'ADD_CATEGORY_TO_STORE';
export const EDIT_CATEGORY               = 'EDIT_CATEGORY';
export const UPDATE_CATEGORY_IN_STORE    = 'UPDATE_CATEGORY_IN_STORE';
export const DELETE_CATEGORY             = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_FROM_STORE  = 'DELETE_CATEGORY_FROM_STORE';
export const UPDATE_CATEGORIES           = 'UPDATE_CATEGORIES';

export const FETCH_POINTS            = 'FETCH_POINTS';
export const REPLACE_POINTS_IN_STORE = 'REPLACE_POINTS_IN_STORE';
export const ADD_POINTS_TO_STORE     = 'ADD_POINTS_TO_STORE';
export const CREATE_POINT            = 'CREATE_POINT';
export const EDIT_POINT              = 'EDIT_POINT';
export const UPDATE_POINT_IN_STORE   = 'UPDATE_POINT_IN_STORE';
export const DELETE_POINT            = 'DELETE_POINT';
export const DELETE_POINT_FROM_STORE = 'DELETE_POINT_FROM_STORE';

export const FETCH_RECEIPTS            = 'FETCH_RECEIPTS';
export const REPLACE_RECEIPTS_IN_STORE = 'REPLACE_RECEIPTS_IN_STORE';
export const ADD_RECEIPTS_TO_STORE     = 'ADD_RECEIPTS_TO_STORE';
export const CREATE_RECEIPT            = 'CREATE_RECEIPT';
export const DELETE_RECEIPT            = 'DELETE_RECEIPT';
export const DELETE_RECEIPT_FROM_STORE = 'DELETE_RECEIPT_FROM_STORE';
export const EDIT_RECEIPT              = 'EDIT_RECEIPT';
export const UPDATE_RECEIPT_IN_STORE   = 'UPDATE_RECEIPT_IN_STORE';

export const FETCH_REWARDS            = 'FETCH_REWARDS';
export const REPLACE_REWARDS_IN_STORE = 'REPLACE_REWARDS_IN_STORE';
export const ADD_REWARDS_TO_STORE     = 'ADD_REWARDS_TO_STORE';
export const CREATE_REWARD            = 'CREATE_REWARD';
export const EDIT_REWARD              = 'EDIT_REWARD';
export const UPDATE_REWARD_IN_STORE   = 'UPDATE_REWARD_IN_STORE';
export const DELETE_REWARD            = 'DELETE_REWARD';
export const DELETE_REWARD_FROM_STORE = 'DELETE_REWARD_FROM_STORE';

export const GET_TEAM          = 'GET_TEAM';
export const ADD_TEAM_TO_STORE = 'ADD_TEAM_TO_STORE';

export const SELECT_SUBJECT    = 'SELECT_SUBJECT';
