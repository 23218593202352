import { call, all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  SELECT_SUBJECT,
  CREATE_POINT,
  FETCH_POINTS,
  DELETE_POINT,
  EDIT_POINT
} from '../actions/types';

import {
  addPointsToStore,
  addPointToStore,
  setStatus,
  deletePointFromStore,
  updatePointInStore,
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( SELECT_SUBJECT,  fetchPoints),
    takeLatest( FETCH_POINTS, fetchPoints),
    takeLatest( CREATE_POINT, createPoint),
    takeLatest( DELETE_POINT, deletePoint),
    takeLatest( EDIT_POINT, editPoint),
  ])
}


function* fetchPoints(action) {

  try {
    const { subject_id } = action.payload;
    const res = yield call( () => axios.get(`/api/user/${subject_id}/points`) );

    yield put( addPointsToStore(res.data) );
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not fetch points'}));
  }

}

function* createPoint(action) {

  try {
    const { subject_id, behavior_id } = action.payload;
    const res = yield call( () => axios.post(`/api/point`, { subject_id, behavior_id } ) );
    //const res = yield call( () => axios.get(`/api/point/8`) );

    yield put( addPointToStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added points'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not add points'}));
  }

}

function* deletePoint(action) {

  try {
    const { point_id } = action.payload;
    yield call( () => axios.delete(`/api/point/${point_id}`) );

    yield put( deletePointFromStore(point_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted point'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete point'}));
  }

}

function* editPoint(action) {

  try {
    const { point_id, notes } = action.payload;
    const res = yield call( () => axios.patch(`/api/point/${point_id}`, { point_id, notes } ));
    //const res = yield call( () => axios.get(`/api/point/8`) );
    yield put( updatePointInStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated point'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not update point'}));
  }

}
