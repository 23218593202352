import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import { getSession } from '../actions';

import CONSTANTS from '../constants';

export default function(ComposedComponent, requiredRank = CONSTANTS.USER.SUBJECT.RANK) {
  class AuthenticatedRoute extends PureComponent {

    bounceIt(session) {
      if (session.invalid || session.rank < requiredRank) {
        this.props.history.push('/session');
      }
      else if (session.requires_reset) {
        this.props.history.push('/password');
      }
      else if (!session.user_id) {
        return this.props.getSession();
      }

    }

    componentWillMount() {
      this.bounceIt(this.props.session);
    }

    componentWillUpdate(nextProps) {
      this.bounceIt(nextProps.session);
    }

    render() {
      if (!this.props.session.user_id || this.props.session.invalid) { return null };

      return <ComposedComponent {...this.props} />
    }
  }

  function mapStateToProps({session}) {
    return { session };
  }

  return withRouter(connect(mapStateToProps, { getSession })(AuthenticatedRoute));
}
