import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import classNames from 'classnames';

import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import { TeamMemberComponent } from './AuthenticatedComponent';

import { deleteReceipt, editReceipt } from '../actions';
import { getSortedReceiptsBySubject } from '../selectors';

class Receipts extends PureComponent {

  state = {
    showReceiptEditor : false,
  }

  constructor(props) {
    super(props);
    this.deleteReceipt             = this.deleteReceipt.bind(this);
  }

  deleteReceipt(receipt_id) {
    if (window.confirm('Really delete receipt?')) {
      this.props.deleteReceipt(receipt_id);
    }
  }

  render() {
    const { receipts } = this.props;

    if (! receipts.length) {
      return <div className='receipts-empty-container'>No defined receipts</div>
    }

    return (
      <Fragment>
        <div className='receipts-title'>
          Redeemed rewards
        </div>
        <div className='receipts-container'>
          <div className='receipt-row receipts-header'>
            <div className='receipt-item'>Date</div>
            <div className='receipt-item'>Time</div>
            <div className='receipt-item'>Redeemed Reward</div>
            <div className='receipt-item'>Points Spent</div>
          </div>
          { receipts.map( receipt => (
            <div className={classNames('receipt-row', receipt.acknowledged ? 'receipt-acknowledged' : 'receipt-unacknowledged')} key={receipt.receipt_id}>
              <div className='receipt-item receipt-date'>{ receipt.date }</div>
              <div className='receipt-item receipt-time'>{ moment(`${receipt.date} ${receipt.time}`).format('h:mm a') }</div>
              <div className='receipt-item receipt-reward'>{ receipt.description }</div>
              <div className='receipt-item receipt-points'>
                { receipt.points }
                <span className='buttons'>
                  <TeamMemberComponent>
                    <Button color='secondary' mini variant='fab' aria-label="delete receipt" onClick={(e) => this.deleteReceipt(receipt.receipt_id)} tabIndex={-1}>
                      <DeleteIcon/>
                    </Button>
                    <Button color={receipt.acknowledged ? undefined : 'primary'} mini variant='fab' aria-label="acknowledge receipt" onClick={(e) => this.props.editReceipt(receipt.receipt_id, { acknowledged : receipt.acknowledged ? 0 : 1})} tabIndex={-1}>
                      <Icon>{receipt.acknowledged ? 'undo' : 'check'}</Icon>
                    </Button>
                  </TeamMemberComponent>
                </span>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    receipts : getSortedReceiptsBySubject(state)(state.subjects.selected),
  }
}

export default connect( mapStateToProps, { deleteReceipt, editReceipt } )(Receipts)
