import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import Subjects from './Subjects';
import { TeamLeadComponent, TeamMemberComponent } from './AuthenticatedComponent';
import RewardEditor from './RewardEditor';
import PointTotals from './PointTotals';
import Receipts from './Receipts';

import { deleteReward, createReceipt } from '../actions';
import { getSortedRewardsBySubject, getTotalAvailablePoints } from '../selectors';

import { USER } from '../constants';

class Rewards extends PureComponent {

  state = {
    showRewardEditor : false,
  }

  constructor(props) {
    super(props);
    this.handleRewardEditorClose  = this.handleRewardEditorClose.bind(this);
    this.editReward               = this.editReward.bind(this);
    this.deleteReward             = this.deleteReward.bind(this);
  }

  handleRewardEditorClose() {
    this.setState(
      {
        reward_id : undefined,
        showRewardEditor:false
      }
    );
  }

  editReward(reward_id) {
    this.setState({
      reward_id : reward_id,
      showRewardEditor : true,
    })
  }

  deleteReward(reward_id) {
    if (window.confirm('Really delete reward?')) {
      this.props.deleteReward(reward_id);
    }
  }

  render() {
    const { rewards, subject_id, totalAvailablePoints, userRank } = this.props;

    if (! rewards.length) {
      return <Fragment>
        <Subjects
          buttonText={'Add Reward'}
          editor={RewardEditor}
        />
        <div className='rewards-empty-container'>No defined rewards</div>
      </Fragment>;
    }

    return (
      <Fragment>
        <TeamMemberComponent>
          <Subjects
            buttonText={'Add Reward'}
            editor={RewardEditor}
          />
        </TeamMemberComponent>
        <PointTotals displayTotal={false} bigDisplay={userRank === USER.SUBJECT.RANK } />
        <div className='rewards-container'>
          <div className='rewards-row rewards-header'>
            <div className='rewards-item'>Reward</div>
            <div className='rewards-item'>Points Required</div>
          </div>
          { rewards.map( reward => (
            <div className='rewards-row' key={reward.reward_id}>
              <div className='rewards-item reward-description'>{ reward.description }</div>
              <div className='rewards-item reward-points'>
                { reward.points }
                <span className='buttons'>
                  <TeamLeadComponent>
                    <Button mini variant='fab' aria-label="delete reward" onClick={(e) => this.editReward(reward.reward_id)} tabIndex={-1}>
                      <Icon>edit_icon</Icon>
                    </Button>
                    <Button color='secondary' mini variant='fab' aria-label="delete reward" onClick={(e) => this.deleteReward(reward.reward_id)} tabIndex={-1}>
                      <DeleteIcon/>
                    </Button>
                  </TeamLeadComponent>
                  <Button
                    color='primary'
                    mini
                    variant='fab'
                    aria-label="record reward points"
                    onClick={ (e) => {
                      this.props.createReceipt( { subject_id, reward_id : reward.reward_id } )
                    }}
                    disabled={totalAvailablePoints - reward.points < 0}
                  ><Icon>redeem</Icon></Button>
                </span>
              </div>
            </div>
          ))}
        </div>
        {/*
          XXX This is a stupid hack. The editor reads its props at construction time, which means they're initialized
          before redux is populated. This waits to create/add the component until after the store exists with the values
          we need. It's stupid.

          It'd be better to just always include the component so we don't re-create and mount it, but the reality is That
          this is so low impact we can get away with it for now. Should be refactored, though.
        */}
        { this.state.showRewardEditor && <RewardEditor
          showEditor={ this.state.showRewardEditor }
          handleEditorClose= { this.handleRewardEditorClose }
          reward_id={this.state.reward_id}
        /> }
        <Receipts />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userRank : state.session.rank,
    rewards : getSortedRewardsBySubject(state)(state.subjects.selected),
    subject_id : state.subjects.selected,
    totalAvailablePoints : getTotalAvailablePoints(state, state.subjects.selected),
  }
}

export default connect( mapStateToProps, { deleteReward, createReceipt } )(Rewards)
