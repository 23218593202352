import {
  LOGOUT_USER,
  SELECT_SUBJECT
} from '../actions/types';

const INITIAL = { };

/*
  Honestly? This reducer should be refactored out of existence and removed.

  Once upon a time, it stored { all : [], selected } where "all" was given by
  the session. It was an array of simple objects {subject_id, subject_name}.
  That was refactored out of existence in favor of the enhanced /users route
  which would return all of the subjects associated with a given user.

  Now, SET_SESSION also triggers a FETCH_USERS, which in turn populates all users.
  subjects are then pulled out of that via a selector - getSubjects.

  So there's no longer any need to stuff that info into the session, since it
  can be queried from the store's users. At that point, this slice was reduced (HA!)
  to just storing subject.selected, which is just the value given back from the subjects
  selector. It should be refactored to something else.

*/

export default (state = INITIAL, action) => {
  switch(action.type) {
    case SELECT_SUBJECT : {
      const { subject_id } = action.payload;
      return { selected : subject_id };
    }
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
