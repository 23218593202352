import { createSelector } from 'reselect';

import { createComparator } from '../utilities';

export const getCategories = (state) => state.categories.all;

const descriptionComparator = createComparator('description', 'asc');

export const getCategoriesSortedByDescription = createSelector(
  [getCategories],
  (categories) => {
    const rows = Object.values(categories).sort(descriptionComparator);
    return rows;
  }
)
