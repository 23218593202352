import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  CREATE_USER,
  EDIT_USER,
  FETCH_USERS,
  DELETE_USER,
  SET_SESSION
} from '../actions/types';

import {
  getSession,
  setStatus,
  replaceUsersInStore,
  deleteUserFromStore,
  updateUserInStore,
  logoutUser,
  selectSubject
} from '../actions';

import { getSubjectsSortedByName } from '../selectors';

import { STATUS} from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( CREATE_USER, createUser),
    takeLatest( EDIT_USER,   editUser),
    takeLatest( DELETE_USER, deleteUser),
    takeLatest( FETCH_USERS, fetchUsers),
    takeLatest( SET_SESSION, fetchUsers),
  ])
}

const demoUsers = {
  1 : {
    user_id : 1,
    team_id : 1,
    user_role_id : 1,
    name : "Demo Admin",
    email : "contact@kighzen.com",
    username: "demo",
    requires_reset : 0,
    subjects : []
  },
  2 : {
    user_id : 2,
    team_id : 1,
    user_role_id : 5,
    name : "Alfred Child",
    username: "achild",
    requires_reset : 0,
    subjects : [],
  }
}

function* fetchUsers(action) {
  const state = yield select();

  try {

    yield put( replaceUsersInStore(demoUsers));

    const state = yield select();

    if ( !state.subjects.selected || !state.users[state.subjects.selected] ) {
      const subjects = getSubjectsSortedByName(state);

      if (subjects.length) {
        yield(put(selectSubject(subjects[0].user_id)));
      }
    }
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not fetch users'}));
  }
}

function* createUser(action) {
  const values = action.payload;
  try {
    let state = yield select();
    const nextUserID = Math.max(0, ...Object.keys(state.users)) + 1;
    const user   = { subjects : [], ...action.payload, user_id : nextUserID };


    yield put( updateUserInStore( user ) );
    state = yield select();
    if ( !state.subjects.selected || !state.users[state.subjects.selected] ) {
      const subjects = getSubjectsSortedByName(state);

      if (subjects.length) {
        yield(put(selectSubject(subjects[0].user_id)));
      }
    }

    yield put( setStatus({type:STATUS.success, message: 'User created'}));

  }
  catch (e) {
      yield put( setStatus({type:STATUS.error, message: 'Could not sign up'}));
  }
}

function* editUser(action) {

  try {
    const state = yield select();

    const { user_id, values } = action.payload;
    const user = { user_id, ...state.users[user_id], ...values };

    yield put( updateUserInStore( user ) );
    if (state.session.user_id === user_id) {
      yield put( getSession() );
    }

    if ( !state.subjects.selected || !state.users[state.subjects.selected] ) {
      const subjects = getSubjectsSortedByName(state);

      if (subjects.length) {
        yield(put(selectSubject(subjects[0].user_id)));
      }
    }

    yield put( setStatus({type:STATUS.success, message: 'User updated'}));
  }
  catch (e) {
      yield put( setStatus({type:STATUS.error, message: 'Could not save user'}));
  }
}

function* deleteUser(action) {
  try {
    const user_id = action.payload;
    const state = yield select();
    yield put( setStatus({type:STATUS.success, message: 'Deleted user'}));
    yield put( deleteUserFromStore(user_id));
    if (state.session.user_id === user_id) {
      yield put( logoutUser() );
    }

    // okay, there is some nasty stuff that happens here. If we delete the
    // currently selected subject, we must re-select the next one.
    if (user_id === state.subjects.selected) {
      state = yield select();
      const subjects = getSubjectsSortedByName(state);

      if (subjects.length) {
        yield(put(selectSubject(subjects[0].user_id)));
      }
      else {
        yield(put(selectSubject(undefined)));
      }
    }
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete user'}));
  }
}
