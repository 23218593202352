import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import { createComparator, comparatorSet, convertObjectToLocalTime } from '../utilities';

export const getReceipts = (state) => state.receipts;

const receiptsComparator    = comparatorSet([
  createComparator('date', 'desc'),
  createComparator('time', 'desc'),
  createComparator('description', 'asc'),
]);

const getReceiptsInLocalTime = createSelector(
  [getReceipts],
  (receipts) => {
    return Object.values(receipts).reduce( (receipts, receipt) => {
      receipts[receipt.receipt_id] = convertObjectToLocalTime(receipt);
      return receipts;
    }, {} );
  }
)

const getReceiptsSortedByName = createSelector(
  [getReceiptsInLocalTime],
  (receipts) => {
    return Object.values(receipts).sort(receiptsComparator);
  }
)

export const getSortedReceiptsBySubject = createSelector(
  [getReceiptsSortedByName],
  receipts => {
    return memoize( subject_id => {
      return receipts
        .filter( receipt => receipt.subject_id === subject_id )
    })
  }
);

export const getTotalReceipts = (receipts) => {
  return receipts.reduce( (total, receipt) => total += receipt.points, 0 );
}
