import {
  LOGIN_USER,
  START_LOGOUT_USER,
  LOGOUT_USER,
  CREATE_USER,
  EDIT_USER,
  FETCH_USERS,
  REPLACE_USERS_IN_STORE,
  UPDATE_USER_IN_STORE,
  DELETE_USER,
  DELETE_USER_FROM_STORE
} from './types';

export const loginUser = ({username, password}) => {
  return {
    type    : LOGIN_USER,
    payload : { username, password }
  }
}

export const logoutUser = () => {
  return {
    type : START_LOGOUT_USER
  }
}

export const loggedoutUser = () => {
  return {
    type : LOGOUT_USER
  }
}

export const createUser = ({user_role_id, name, username, password, requires_reset, subject_ids, email}) => {
  return {
    type    : CREATE_USER,
    payload : { user_role_id, name, username, password, requires_reset, subject_ids, email }
  }
}

export const editUser = (user_id, values) => {
  return {
    type    : EDIT_USER,
    payload : {user_id, values}
  }
}

export const updateUserInStore = (user) => {
  return {
    type    : UPDATE_USER_IN_STORE,
    payload : user
  }
}

export const fetchUsers = () => {
  return {
    type : FETCH_USERS
  }
}

export const replaceUsersInStore = (users) => {
  return {
    type    : REPLACE_USERS_IN_STORE,
    payload : users
  }
}

export const deleteUser = (user_id) => {
  return {
    type    : DELETE_USER,
    payload : user_id
  }
}

export const deleteUserFromStore = (user_id) => {
  return {
    type    : DELETE_USER_FROM_STORE,
    payload : user_id
  }
}
