import {
  LOGOUT_USER
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
