import React, { PureComponent } from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    width : 300,
    marginTop : '50px',
    marginLeft : 'auto',
    marginRight : 'auto'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    //textAlign : 'center',
  },
  cardActions : {
    display : 'flex',
    justifyContent : 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  p : {
    marginTop : '10px',
  }
});

class Welcome extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Paper className='welcome-paper'>
        <div className='welcome-box'>
          <Typography variant='h4' className={classes.header} component='h4'>
            Welcome to Kighzen!
          </Typography>
          <Typography variant='body2' component='p' className={classes.p}>
            <a href = 'https://en.wikipedia.org/wiki/Kaizen' target='_blank' rel="noopener noreferrer">Kaizen</a> (改善) is the Japanese word for improvement.
            In business, the goal is to continuously improve all the time in all ways. Constant incremental improvement will
            increase over time, leading to big gains and outcomes in the future.
          </Typography>
          <Typography variant='body2' component='p' className={classes.p}>
            Here at <b>Kighzen</b>, we're developing a new app to help provided feedback and encouragement to people who are having
            trouble reaching their goals, or finding the motivation to do so. We're mainly trying to work with people living
            with autism spectrum disorders.
          </Typography>
          <Typography variant='body2' component='p' className={classes.p}>
            We know that it's really difficult to make positive changes to behavior on your own. It's even difficult to help
            someone make those changes. We need teams of people working together, all encouraging good behavior and discouraging
            bad behavior to try and improve and work towards our goals.
          </Typography>
          <Typography variant='body2' component='p' className={classes.p}>
            Unlike simple token economy systems, <b>Kighzen</b> implements a team-based approach: This allows you to coordinate across
            all the staff you have working together for better outcomes:
          </Typography>
          <Typography variant='body2' component='ul' className={classes.p}>
            <li>Your <b>Team Lead</b> will coordinate the behaviors you want to enforces, and the rewards which can be earned.</li>
            <li>Your <b>Team Members</b> are your outside staff who come in to help encourage and track the behaviors you want to see.</li>
            <li>Your <b>Family Members</b> are the people in your household tracking and encouraging (and rewarding!) the behavior.</li>
            <li>And Your <b>Subject</b> is the focus of everything. This is the person you're trying to help improve by helping to keep
            track of their good (and bad. But mostly good!) behaviors to build up towards rewards.</li>
          </Typography>
          <Typography variant='body2' component='p' className={classes.p}>
            <b>Kighzen</b> is currently in an open beta as we iron out the kinks and get ready to launch. If you're interested in finding
            out more (and maybe helping us beta test!), please drop us a note at <b>contact (at) (our domain name - kighzen dot com)</b> to
            let us know you're interested and we'll try to get back to you soon! <b>Or you can just click the "Try a demo" button up at the top to start
            clicking around and try it out.</b>
          </Typography>
          <Typography variant='body2' component='p' className={classes.p}>
            You can check out a quick intro video here: <a href = 'https://youtu.be/95MZPwgb1_4' target='_blank' rel="noopener noreferrer">Kaizen Quick Intro</a>
          </Typography>
          <Typography variant='body2' component='p' className={classes.p}>
            And there's an in-depth tutorial video here: <a href = 'https://youtu.be/mHYl-pFws88' target='_blank' rel="noopener noreferrer">Kighzen Setup Tutorial</a>
          </Typography>
        </div>
        <div className='welcome-images'>
          <div className='welcome-image'>
            <img src = '/images/admin-behavior.png' alt='Admin screen, behavior view' />
            <div>Your team lead will set up the behaviors you want to track and encourage.</div>
          </div>
        </div>
        <div className='welcome-images-carousel'>
          <div className='welcome-image'>
            <img src = '/images/admin-reports.png' alt='Admin screen, reports view'  />
            <div>Reports let you track progress towards goals.</div>
          </div>
          <div className='welcome-image'>
            <img src = '/images/admin-rewards.png' alt='Admin screen, rewards view'  />
            <div>Rewards help encourage people to meet their goals! Good behaviors earn more points,
            which earn bigger rewards.</div>
          </div>
          <div className='welcome-image'>
            <img src = '/images/therapist-behaviors.png' alt='Therapist screen, behavior view'  />
            <div>Security is important at Kighzen — set team member level permissions so your team members
            can only view data on their Subjects.</div>
          </div>
          <div className='welcome-image'>
            <img src = '/images/subject-rewards.png' alt='Subject screen, rewards view'  />
            <div>The Subject sees the points they've accrued and what they're working towards, as well
            as which rewards they've already claimed (including pending ones!).</div>
          </div>
        </div>
      </Paper>
    )
  }
}

export default withStyles(styles)(Welcome);
