import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { TeamLeadComponent } from './AuthenticatedComponent';
import SubjectSelector from './SubjectSelector';

import { selectSubject } from '../actions';

const styles = theme => ({
  addButton : {
    minWidth : '120px', //102px is all that's necessary
    marginLeft : '5px'
  }
});

class Subjects extends PureComponent {

  static defaultProps = {
    buttonText : ''
  }

  state = {
    showEditor : false
  }

  constructor(props) {
    super(props);
    this.handleEditorClose  = this.handleEditorClose.bind(this);
    this.openEditor         = this.openEditor.bind(this);
  }

  handleEditorClose() {
    this.setState({showEditor:false});
  }

  openEditor() {
    this.setState({showEditor:true});
  }

  render() {

    const { selected, classes, buttonText, editor : Editor } = this.props;

    return (
      <div className={'subject-selector-control'}>
        <SubjectSelector selectSubject={this.props.selectSubject} />
        <TeamLeadComponent>
          { buttonText && selected && <Button
            variant = 'contained'
            color='primary'
            size="small"
            className={classes.addButton}
            onClick={this.openEditor}
            tabIndex={-1}
          >
            { buttonText }
          </Button> }
          {/*
            XXX This is a stupid hack. The editor reads its props at construction time, which means they're initialized
            before redux is populated. This waits to create/add the component until after the store exists with the values
            we need. It's stupid.

            It'd be better to just always include the component so we don't re-create and mount it, but the reality is That
            this is so low impact we can get away with it for now. Should be refactored, though.
          */}
          { this.state.showEditor && <Editor
            showEditor={ this.state.showEditor }
            handleEditorClose= { this.handleEditorClose }
          /> }
        </TeamLeadComponent>
      </div>
    );
  }
}

const mapStateToProps = ({subjects}) => {
  return {
    subjects : subjects.all,
    selected : subjects.selected
  }
};

export default connect(mapStateToProps, { selectSubject } )(
  withStyles(styles)(Subjects)
);
