import { call, all, put, takeLatest } from 'redux-saga/effects';

import {
  LOGIN_USER,
  START_LOGOUT_USER,
  GET_SESSION,
} from '../actions/types';

import { setSession, setStatus, loggedoutUser } from '../actions';

import { STATUS, USER } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( LOGIN_USER,        loginUser),
    takeLatest( START_LOGOUT_USER, logoutUser),
    takeLatest( GET_SESSION,       getSession),
  ])
}

const sampleSession = {
    "user_id": 1,
    "team_id": 1,
    "name": "Demo User",
    "requires_reset": 0,
    "rank": USER.ADMIN.RANK
};

function* loginUser(action) {

  try {
    yield put( setSession(sampleSession))
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not log in'}));
    yield put( setSession({invalid : true}) );
  }

}

function* logoutUser(action) {
  try {
    yield put( loggedoutUser() );
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not log out'}));
  }
}

function* getSession(action) {

  try {
    yield put( setSession(sampleSession) );
  }
  catch(e) {
    yield(put(setSession({invalid : true})));
  }
}
