import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getSortedPointsBySubject, getPointTotals, getTotalPoints, getTotalAvailablePoints } from '../selectors';

class PointTotals extends PureComponent {

  static defaultProps = {
    displayTotal     : true,
    displayAvailable : true,
  }

  render() {

    const { totalPoints, totalAvailable, displayTotal, displayAvailable, bigDisplay } = this.props;

    if (bigDisplay) {
      return (
        <div className='point-totals-container bigDisplay'>
          { displayTotal && <div className='total-points'>
            <div className='points'>{ totalPoints }</div>
            <div>points</div>
          </div> }
          { displayAvailable && <div className='total-points'>
            <div className='points'>{ totalAvailable }</div>
            <div>points available</div>
          </div> }
        </div>
      )
    }

    return (
      <div className='point-totals-container'>
        { displayTotal && <div className='total-points'>
          <span>Total points earned:</span>
          <span>{ totalPoints }</span>
        </div> }
        { displayAvailable && <div className='total-points'>
          <span>Total points available:</span>
          <span>{ totalAvailable }</span>
        </div> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  const points         = getSortedPointsBySubject(state)(state.subjects.selected);
  const totalPoints    = getTotalPoints(getPointTotals(points));
  const totalAvailable = getTotalAvailablePoints(state, state.subjects.selected);

  return {
    totalPoints,
    totalAvailable,
  }
}

export default connect(mapStateToProps)(PointTotals);
