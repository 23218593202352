import {
  REPLACE_REWARDS_IN_STORE,
  ADD_REWARDS_TO_STORE,
  DELETE_REWARD_FROM_STORE,
  UPDATE_REWARD_IN_STORE,
  LOGOUT_USER
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case REPLACE_REWARDS_IN_STORE :
      return action.payload;
    case ADD_REWARDS_TO_STORE :
      const rewards = action.payload;
      return { ...state, ...rewards };
    case DELETE_REWARD_FROM_STORE : {
      const { reward_id } = action.payload;
      const newState = {...state};
      delete newState[reward_id];
      return newState;
    }
    case UPDATE_REWARD_IN_STORE : {
      const reward = action.payload;
      return {...state, [reward.reward_id] : reward };
    }
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
