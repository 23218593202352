import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { TeamLeadComponent } from './AuthenticatedComponent';

import { USER } from '../constants';

class Navigation extends PureComponent {

  constructor(props) {
    super(props);

    this.navigateDashboard = () => this.navigate('/dashboard');
    this.navigateUsers     = () => this.navigate('/users');
    this.navigateRewards   = () => this.navigate('/rewards');
    this.navigateReports   = () => this.navigate('/report');
  }

  navigate(route) {
    this.props.history.push(route);
  }

  render() {

    const pathname = this.props.location.pathname;

    return (
      <div className={classNames('app-navigation', this.props.userRank >= USER.TEAMLEAD.RANK ? 'team-lead-navigation' : 'not-team-lead-navigation')}>
        <Button
          type='submit'
          variant = 'contained'
          color={ pathname === '/dashboard' ? 'secondary' : 'primary' }
          size="small"
          onClick={ this.navigateDashboard }>Behaviors</Button>
        <Button
          type='submit'
          variant = 'contained'
          color={ pathname === '/report' ? 'secondary' : 'primary' }
          size="small"
          onClick={ this.navigateReports }>Reports</Button>
        <Button
          type='submit'
          variant = 'contained'
          color={ pathname === '/rewards' ? 'secondary' : 'primary' }
          size="small"
          onClick={ this.navigateRewards }>Rewards</Button>
        <TeamLeadComponent>
          <Button
            type='submit'
            variant = 'contained'
            color={ pathname === '/users' ? 'secondary' : 'primary' }
            size="small"
            onClick={ this.navigateUsers }>Users</Button>
        </TeamLeadComponent>
      </div>
    )
  }
}

const mapStateToProps = ({session}) => {
  return {
    userRank : session.rank
  }
}

export default withRouter(
  connect(mapStateToProps)(Navigation)
);
