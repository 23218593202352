import {
  SELECT_SUBJECT
} from './types';

export const selectSubject = (subject_id) => {
  return {
    type    : SELECT_SUBJECT,
    payload : { subject_id }
  }
};
