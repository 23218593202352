import {
  LOGOUT_USER,
  REPLACE_USERS_IN_STORE,
  DELETE_USER_FROM_STORE,
  UPDATE_USER_IN_STORE
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case REPLACE_USERS_IN_STORE :
      return action.payload;
    case DELETE_USER_FROM_STORE : {
      const user_id = action.payload;
      let newState = {...state};
      delete newState[user_id];
      return newState;
    }
    case UPDATE_USER_IN_STORE : {
      const user = action.payload;
      return { ...state, [user.user_id] : user };
    }
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
