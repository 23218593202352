import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

import 'typeface-roboto';
import 'font-awesome/css/font-awesome.min.css';

import reducers from './reducers';
import rootSaga from './sagas';
import demoSaga from './demo-sagas';

const sagaMiddleware = createSagaMiddleware();

const createStoreWithMiddleware = applyMiddleware(sagaMiddleware)(createStore);

export const store = createStoreWithMiddleware(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const demo = window.location.search.match(/demo/);

sagaMiddleware.run(
  demo
    ? demoSaga
    : rootSaga
);

//this shuts up a typography deprecated warning
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <React.Fragment>
          <CssBaseline/>
          <App />
        </React.Fragment>
      </MuiPickersUtilsProvider>
    </Router>
  </Provider>
, document.getElementById('root'));

serviceWorker.unregister();
