import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';

import {
  SET_SESSION,
  FETCH_CATEGORIES,

  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORIES,
} from '../actions/types';

import {
  replaceCategoriesInStore,
  addCategoryToStore,
  updateCategoryInStore,
  deleteCategoryFromStore,
  selectCategory,
  setStatus
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( SET_SESSION,      fetchCategories),
    takeLatest( FETCH_CATEGORIES, fetchCategories),
    takeLatest( CREATE_CATEGORY, createCategory),
    takeLatest( EDIT_CATEGORY, editCategory),
    takeLatest( DELETE_CATEGORY, deleteCategory),
    takeLatest( UPDATE_CATEGORIES, updateCategories),
  ])
}

function* fetchCategories(action) {
  const state = yield select();

  if (state.session.user_id) {
    try {

      const res = yield call( () => axios.get(`/api/categories`) );
      yield put( replaceCategoriesInStore(res.data) );
    }
    catch (e) {
      yield put( setStatus({type:STATUS.error, message: 'Could not fetch categories'}));
    }
  }

}

function* createCategory(action) {

  try {
    const category = action.payload;

    const res = yield call( () => axios.post(`/api/category`, category ));
    yield put( addCategoryToStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added category'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not create new category'}));
  }

}

function* editCategory(action) {

  try {
    const { category, category_id } = action.payload;

    const res = yield call( () => axios.patch(`/api/category/${category_id}`, category ));
    yield put( updateCategoryInStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated category'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not edit category'}));
  }

}

function* deleteCategory(action) {

  try {
    const category_id = action.payload;
    yield call( () => axios.delete(`/api/category/${category_id}`));
    yield put( deleteCategoryFromStore(category_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted category'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete category'}));
  }

}

function* updateCategories(action) {

  const categories = action.payload;

  const state = yield select();
  const selectedCategoryID = state.categories.selected;

  try {

    const res = yield call( () => axios.put(`/api/categories`, categories) );

    yield put( replaceCategoriesInStore(res.data) );
    yield put( selectCategory(selectedCategoryID));
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated categories'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not update categories'}));
  }

}
