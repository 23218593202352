import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';

import { FamilyMemberComponent } from './AuthenticatedComponent';
import CategoryEditor from './CategoryEditor';

import { selectCategory } from '../actions';
import { getCategoriesSortedByDescription } from '../selectors';

import { ALL_CATEGORIES } from '../constants';

const styles = theme => ({
  formControl: {
    //margin: theme.spacing.unit,
    //minWidth: 120,
    width : '100%',
    //margin : '8px'
    //display : 'grid',
    //gridTemplateColumns : '1fr 1fr'
  },
  editCategoriesButton : {
    minWidth : '120px',
    marginLeft : '5px'
  }
});

class Categories extends PureComponent {

  state = {
    showCategoryEditor : false
  }

  constructor(props) {
    super(props);

    this.onChange                   = this.onChange.bind(this);
    this.handleCategoryEditorClose  = this.handleCategoryEditorClose.bind(this);
    this.editCategories             = this.editCategories.bind(this);
  }

  onChange(e) {
    e.preventDefault(); e.stopPropagation();

    const newValue = parseInt(e.target.value, 10);

    this.props.selectCategory( newValue );
  }

  handleCategoryEditorClose() {
    this.setState(
      {
        showCategoryEditor:false
      }
    );
  }

  editCategories(category_id) {
    this.setState({
      showCategoryEditor : true,
    })
  }

  render() {

    const { categories, classes } = this.props;

    return (
      <div className={'category-selector-control'}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Selected Category</InputLabel>
          <NativeSelect
            native='true'
            value={this.props.selected}
            onChange={this.onChange}
            inputProps={{
              name: 'category',
              id: 'category_id',
            }}
          >
            <option value={ALL_CATEGORIES} key={'all'}>All categories</option>
            { categories.map( category => (
              <option value={category.category_id} key={category.category_id}>{category.description}</option>
            ))}
          </NativeSelect>
        </FormControl>
        <FamilyMemberComponent>
          <Button
            variant = 'contained'
            color='primary'
            size="small"
            className={classes.editCategoriesButton}
            onClick={this.editCategories}
            tabIndex={-1}
          >
            Edit categories
          </Button>
          {/*
            XXX This is a stupid hack. The editor reads its props at construction time, which means they're initialized
            before redux is populated. This waits to create/add the component until after the store exists with the values
            we need. It's stupid.

            It'd be better to just always include the component so we don't re-create and mount it, but the reality is That
            this is so low impact we can get away with it for now. Should be refactored, though.
          */}
          { this.state.showCategoryEditor && <CategoryEditor
            showCategoryEditor={ this.state.showCategoryEditor }
            handleCategoryEditorClose= { this.handleCategoryEditorClose }
          /> }
        </FamilyMemberComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories : getCategoriesSortedByDescription(state),
    selected   : state.categories.selected
  }
};

export default connect(mapStateToProps, { selectCategory } )(
  withStyles(styles)(Categories)
);
