import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';

import {
  SET_SESSION,
  FETCH_CATEGORIES,

  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORIES,
} from '../actions/types';

import {
  replaceCategoriesInStore,
  addCategoryToStore,
  updateCategoryInStore,
  deleteCategoryFromStore,
  selectCategory,
  setStatus
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( CREATE_CATEGORY,   createCategory),
    takeLatest( EDIT_CATEGORY,     editCategory),
    takeLatest( DELETE_CATEGORY,   deleteCategory),
    takeLatest( UPDATE_CATEGORIES, updateCategories),
  ])
}

function* createCategory(action) {

  try {

    const state = yield select();
    const nextCategoryID = Math.max(0, ...Object.keys(state.categories.all)) + 1;

    const category = { ...action.payload, category_id : nextCategoryID };

    yield put( addCategoryToStore(category) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added category'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not create new category'}));
  }

}

function* editCategory(action) {

  try {
    const { category } = action.payload;

    yield put( updateCategoryInStore(category) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated category'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not edit category'}));
  }

}

function* deleteCategory(action) {

  try {
    const category_id = action.payload;
    yield put( deleteCategoryFromStore(category_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted category'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete category'}));
  }

}

function* updateCategories(action) {

  const categories = action.payload.reduce( (categories, category, idx) => {
    categories[idx] = {
      category_id : idx,
      team_id : 1,
      description : category.description
    }
    return categories;
  }, {});

  const state = yield select();

  try {
    yield put( replaceCategoriesInStore(categories) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated categories'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not update categories'}));
  }

}
