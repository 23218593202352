import {
  FETCH_RECEIPTS,
  REPLACE_RECEIPTS_IN_STORE,
  CREATE_RECEIPT,
  ADD_RECEIPTS_TO_STORE,
  DELETE_RECEIPT,
  DELETE_RECEIPT_FROM_STORE,
  EDIT_RECEIPT,
  UPDATE_RECEIPT_IN_STORE
} from './types';

export const fetchReceipts = (subject_id) => {
  return {
    type    : FETCH_RECEIPTS,
    payload : { subject_id }
  }
}

export const replaceReceiptsInStore = (receipts) => {
  return {
    type    : REPLACE_RECEIPTS_IN_STORE,
    payload : receipts
  }
}

export const createReceipt = ({ subject_id, reward_id, date, time }) => {
  return {
    type    : CREATE_RECEIPT,
    payload : { subject_id, reward_id, date, time }
  }
}

export const addReceiptsToStore = (receipt) => {
  return {
    type    : ADD_RECEIPTS_TO_STORE,
    payload : receipt
  }
}

export const addReceiptToStore = (receipt) => {
  return {
    type    : ADD_RECEIPTS_TO_STORE,
    payload : { [receipt.receipt_id] : receipt }
  }
}

export const deleteReceipt = (receipt_id) => {
  return {
    type    : DELETE_RECEIPT,
    payload : { receipt_id }
  }
}

export const deleteReceiptFromStore = (receipt_id) => {
  return {
    type    : DELETE_RECEIPT_FROM_STORE,
    payload : { receipt_id }
  }
}

export const editReceipt = (receipt_id, {acknowledged}) => {
  return {
    type    : EDIT_RECEIPT,
    payload : { receipt_id, acknowledged }
  }
}

export const updateReceiptInStore = (receipt) => {
  return {
    type    : UPDATE_RECEIPT_IN_STORE,
    payload : receipt
  }
}
