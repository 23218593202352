const CONSTANTS = {
  USER : {
    ADMIN         : { ID : 1, RANK : 50, NAME : 'admin' },
    TEAMLEAD      : { ID : 2, RANK : 40, NAME : 'team lead' },
    FAMILYMEMBER  : { ID : 3, RANK : 30, NAME : 'family member' },
    TEAMMEMBER    : { ID : 4, RANK : 20, NAME : 'team member' },
    SUBJECT       : { ID : 5, RANK : 10, NAME : 'child' },
  },
  BCRYPT_COST : 8,

  STATUS : {
    error   : 'error',
    warning : 'warning',
    success : 'success',
    info    : 'info',
  },

  DAY_FORMAT  : 'YYYY-MM-DD',
  TIME_FORMAT : 'HH:mm',

  ALL_CATEGORIES : -1,

  ROW_HEIGHT     : 53,
};

module.exports = CONSTANTS;
