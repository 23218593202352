import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { editUser } from '../actions';

const styles = theme => ({
  card: {
    width : 300,
    marginTop : '50px',
    marginLeft : 'auto',
    marginRight : 'auto'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    textAlign : 'center',
  },
  cardActions : {
    display : 'flex',
    justifyContent : 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class Password extends PureComponent {

  static propTypes = {
    classes : PropTypes.object.isRequired
  }

  state = {
    passwordA : '',
    passwordB : '',
    password  : '',
  }

  constructor(props) {
    super(props);

    this.savePassword  = this.savePassword.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);

  }

  savePassword(e) {
    const id = e.target.id;
    const value = e.target.value;
    this.setState({[id] : value}, this.challengePassword);
  }

  challengePassword() {

    if (this.state.passwordA !== this.state.passwordB) {
      this.setState({error : true});
      this.setState({'password' : undefined});
    }
    else {
      this.setState({error : false});
      this.setState({'password' : this.state.passwordA})
    }
  }

  canSubmit() {
    return this.state.password !== undefined && this.state.password.length;
  }

  handleSubmit(e) {
    e.preventDefault(); e.stopPropagation();
    this.props.editUser(this.props.session.user_id, { password : this.state.password } );
  }

  render() {

    const { classes, session } = this.props;

    if (!session.requires_reset) {
      return <Redirect to='/dashboard' />;
    }

    return (
      <div className='session-block'>
        <form>
          <Typography variant='h4' className={classes.header}>
            Your password has expired, please reset it.
          </Typography>
          <Card className={classes.card}>
            <CardContent>
              <div>
                <TextField
                  id="passwordA"
                  label="New Password"
                  type='password'
                  className={classes.textField}
                  margin="normal"
                  onChange={ this.savePassword }
                  value={this.state.passwordA}
                  autoComplete="no"
                />
              </div>
              <div>
                <TextField
                  id="passwordB"
                  label="Confirm New Password"
                  type='password'
                  className={classes.textField}
                  margin="normal"
                  onChange={ this.savePassword }
                  value={this.state.passwordB}
                  error={this.state.error}
                  helperText={ this.state.error ? 'passwords do not match' : undefined }
                  autoComplete="no"
                />
              </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                type='submit'
                disabled={!this.canSubmit()}
                variant = 'contained'
                color='primary'
                size="small"
                onClick={this.handleSubmit}>Reset password</Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ( {session, status} ) => {
  return { session }
}

export default withStyles(styles)(
  connect(mapStateToProps, {editUser})(Password)
);
