import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import { createComparator } from '../utilities';

import { ALL_CATEGORIES } from '../constants';

export const getBehaviors = (state) => state.behaviors;

const pointsComparator    = createComparator('points', 'desc', 'numeric');

const getSortedBehaviors = createSelector(
  [getBehaviors],
  (behaviors) => {
    return Object.values(behaviors).sort(pointsComparator);
  }
)

export const getSortedBehaviorsInCategory = createSelector(
  [getSortedBehaviors],
  behaviors => {
    return memoize( (category_id, subject_id = undefined) => {

      return behaviors
        .filter( behavior => category_id === ALL_CATEGORIES || behavior.category_ids.includes(category_id) )
        .filter( behavior => subject_id === undefined || behavior.subject_id === subject_id )
    })
  }
);
