import moment from 'moment';

import { DAY_FORMAT, TIME_FORMAT } from './constants';

export const compareOnField = (objA, objB, field) => {
  return String(objA[field]).localeCompare(String(objB[field]), window.navigator.language, { numeric : true });
}

export const numericCompareOnField = (mealA, mealB, field) => {
  const fieldA = mealA[field] || 0;
  const fieldB = mealB[field] || 0;
       if (fieldA > fieldB) { return  1 }
  else if (fieldA < fieldB) { return -1 }
  else                      { return  0 }
}


export const createComparator = (field, direction, numeric = false) =>{

  const comparison = numeric
    ? numericCompareOnField
    : compareOnField;

  if (direction === 'asc') {
    return (objA, objB) => {
      return comparison(objA, objB, field);
    }
  }
  else {
    return (objA, objB) => {
      return -comparison(objA, objB, field);
    }
  }
}

export const comparatorSet = (comparators) => {
  return (trxA, trxB) => {
    for (const comparator of comparators) {
      const res = comparator(trxA, trxB);
      if (res) { return res };
    }

    return 0;
  }
}

export const convertObjectToLocalTime = (obj) => {
  var localTime = moment.utc(`${obj.date} ${obj.time}`).local();

  return {
    ...obj,
    date : localTime.format(DAY_FORMAT),
    time : localTime.format(TIME_FORMAT),
  }
}
