import { call, all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  SELECT_SUBJECT,
  CREATE_RECEIPT,
  FETCH_RECEIPTS,
  DELETE_RECEIPT,
  EDIT_RECEIPT
} from '../actions/types';

import {
  replaceReceiptsInStore,
  addReceiptsToStore,
  addReceiptToStore,
  setStatus,
  deleteReceiptFromStore,
  updateReceiptInStore,
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( SELECT_SUBJECT,  fetchReceipts),
    takeLatest( FETCH_RECEIPTS, fetchReceipts),
    takeLatest( CREATE_RECEIPT,  createReceipt),
    takeLatest( DELETE_RECEIPT,  deleteReceipt),
    takeLatest( EDIT_RECEIPT,    editReceipt),
  ])
}


function* fetchReceipts(action) {

  try {
    const { subject_id } = action.payload;
    const res = yield call( () => axios.get(`/api/user/${subject_id}/receipts`) );

    yield put( addReceiptsToStore(res.data) );
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not fetch receipts'}));
  }

}

function* createReceipt(action) {

  try {
    const { subject_id, reward_id, date, time } = action.payload;
    const res = yield call( () => axios.post(`/api/receipt`, { subject_id, reward_id, date, time } ) );

    yield put( addReceiptToStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added receipt'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not add receipt'}));
  }

}

function* deleteReceipt(action) {

  try {
    const { receipt_id } = action.payload;
    yield call( () => axios.delete(`/api/receipt/${receipt_id}`) );

    yield put( deleteReceiptFromStore(receipt_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted receipt'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete receipt'}));
  }

}

function* editReceipt(action) {

  try {
    const { receipt_id, acknowledged } = action.payload;
    const res = yield call( () => axios.patch(`/api/receipt/${receipt_id}`, { acknowledged } ));

    yield put( updateReceiptInStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated receipt'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not update receipt'}));
  }

}
