import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import moment from 'moment';

import { getSortedReceiptsBySubject, getTotalReceipts } from './receipt';

import { createComparator, comparatorSet, convertObjectToLocalTime } from '../utilities';
import { DAY_FORMAT } from '../constants';

export const getPoints   = (state) => state.points;
export const getReceipts = (state) => state.receipts;

const pointsComparator    = comparatorSet([
  createComparator('date', 'desc'),
  createComparator('time', 'desc'),
  createComparator('points', 'desc', 'numeric'),
]);

const getPointsInLocalTime = createSelector(
  [getPoints],
  (points) => {
    return Object.values(points).reduce( (points, point) => {
      points[point.point_id] = convertObjectToLocalTime(point);
      return points;
    }, {} );
  }
)

const getSortedPoints = createSelector(
  [getPointsInLocalTime],
  (points) => {
    return Object.values(points).sort(pointsComparator);
  }
)

export const getSortedPointsBySubject = createSelector(
  [getSortedPoints],
  points => {
    return memoize( subject_id => {
      return points
        .filter( point => point.subject_id === subject_id )
    })
  }
);

// XXX NOTE - THIS REDUCER IS IMPURE! BECAUSE THE TOTAL CAN CHANGE WHEN THE DAY CHANGES!
// THIS NEEDS TO BE REFACTORED SO 'today' IS PART OF THE STORE.

export const getPointTotals = (points) => {
  const today = moment().format(DAY_FORMAT);

  const interimObj = Object.values(points).reduce( (totals, point) => {
    if (totals[point.date] === undefined) {
      totals[point.date] = 0;
    }
    totals[point.date] += point.points;
    return totals;
  }, {} );

  return Object.keys(interimObj).reduce( (totals, date) => {
    if (date !== today && interimObj[date] < 0) {
      totals[date] = 0;
    }
    else {
      totals[date] = interimObj[date];
    }
    return totals;
  }, {});
};

export const getTotalPoints = (totals) => {
  return Object.values(totals).reduce( (total, points) => total += points, 0 )
};

export const getTotalAvailablePoints = (state, subject_id) => {
  const totalPoints   = getTotalPoints(getPointTotals(getSortedPointsBySubject(state)(subject_id)));
  const totalReceipts = getTotalReceipts(getSortedReceiptsBySubject(state)(subject_id));

  return totalPoints - totalReceipts;
}
