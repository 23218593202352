import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteIcon from '@material-ui/icons/Delete';

import { updateCategories } from '../actions';
import { getCategoriesSortedByDescription } from '../selectors';

const styles = theme => ({
  textField: {
    marginLeft: 0,//theme.spacing.unit,
    marginRight: 0,//theme.spacing.unit,
    width: '100%',
    marginTop : 0,
  },
  dateTimeField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    marginTop : '16px',
    marginBottom : '16px'
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  dialogContent: {
    width : 300,
    marginLeft : 'auto',
    marginRight : 'auto',
    marginTop : 0,
    paddingBottom : 0,
    paddingRight : 8,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    textAlign : 'center',
  },
  dialogActions : {
    display : 'flex',
    justifyContent : 'space-between',
  },
  formControl: {
    width : '100%',
  },
});

class CategoryEditor extends PureComponent {

  state = {
    categories : this.props.categories
  }

  constructor(props) {
    super(props);

    this.nextCatKey       = -1;

    this.changeValue   = this.changeValue.bind(this);
    this.canSubmit     = this.canSubmit.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.deleteCategory= this.deleteCategory.bind(this);
  }

  changeValue(e) {
    return this.changeCategoryValue(parseInt(e.target.id, 10), e.target.value);
  }

  deleteCategory(category_id, description) {
    if (window.confirm(`Really delete category "${description}"?`)) {
      this.changeCategoryValue(category_id, undefined);
    }
  }

  changeCategoryValue(category_id, description) {

    let found = false;
    const categories = this.state.categories.reduce( (categories, c) => {
      if (c.category_id === category_id) {
        found = true;
        const newCategory = { category_id };
        if (description !== undefined) {
          newCategory.description = description;
        }
        categories.push( newCategory )
      }
      else {
        categories.push(c);
      }
      return categories;
    }, []);

    if (found) {
      this.setState( {categories } );
    }
    else {
      this.setState( { categories : [...categories, { category_id, description } ] } );
    }
  }

  handleSubmit(e) {
    e.preventDefault(); e.stopPropagation();

    const categories = this.state.categories.map( category => {
      if (category.category_id > 0) {
        return category;
      }
      else {
        return { description : category.description };
      }
    })
    .filter( category => category.category_id || category.description );

    this.props.updateCategories( categories );

    if (this.props.saveCallback) {
      this.props.saveCallback();
    }

    this.props.handleCategoryEditorClose();
  }

  canSubmit() {
    const existingNames = new Set();
    return this.state.categories.reduce( (canSubmit, category) => {
      canSubmit = canSubmit
        && ( category.description === undefined || category.description.length )
        && ( !existingNames.has(category.description) );
      if (category.description !== undefined) {
        existingNames.add(category.description);
      }
      return canSubmit;
    }, true);
  }

  renderTextField({id, label, value, rows = 1, type = 'text', onChange=this.changeValue}) {
    return (
      <TextField
        id={String(id)}
        label={label}
        className={this.props.classes.textField}
        type={type}
        value={value}
        margin="normal"
        onChange={ onChange }
        multiline={rows > 1}
        rows={rows}
        key={String(id)}
      />
    )
  }

  nextKey() {
    return this.nextCatKey--;
  }

  render() {

    const { classes }     = this.props;
    let   categories  = [...this.state.categories, { category_id : this.nextKey(), description : '', new : true } ];

    return (
      <Dialog
        onClose={this.props.handleCategoryEditorClose}
        aria-labelledby="category-editor-title"
        open={this.props.showCategoryEditor}
        scroll='paper'>
        <form>
          <DialogTitle id="category-editor-title">Edit Categories</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <div>
                { categories
                  .filter( category => category.description !== undefined )
                  .map( category => (
                  <div className='category-editor-row' key={category.key || category.category_id}>
                    { this.renderTextField( { id : category.category_id, label : '', value : category.description }) }
                    { !category.new &&
                      <Button
                        color='secondary'
                        mini
                        variant='fab'
                        aria-label="delete behavior"
                        onClick={(e) => this.deleteCategory(category.category_id, category.description)}
                        tabIndex={-1}
                        >
                        <DeleteIcon/>
                      </Button>
                    }
                  </div>
                ) ) }
                { /*this.renderTextField({id : 'description', label : 'Description', value : description, rows : 3})*/ }
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                type='button'
                variant = 'contained'
                color='secondary'
                size="small"
                onClick={this.props.handleCategoryEditorClose}>Cancel</Button>
              <Button
                type='submit'
                disabled={!this.canSubmit()}
                variant = 'contained'
                color='primary'
                size="small"
                onClick={this.handleSubmit}>Save</Button>
            </DialogActions>
          </form>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => {

  return {
    categories : getCategoriesSortedByDescription(state)
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, { updateCategories })(CategoryEditor)
);
