import {
  GET_SESSION,
  SET_SESSION
} from './types';

export const setSession = (session) => {
  return {
    type    : SET_SESSION,
    payload : session
  }
}

export const getSession = (session) => {
  return {
    type : GET_SESSION
  }
}
