import {
  REPLACE_POINTS_IN_STORE,
  ADD_POINTS_TO_STORE,
  DELETE_POINT_FROM_STORE,
  UPDATE_POINT_IN_STORE,
  LOGOUT_USER
} from '../actions/types';

const INITIAL = {};

export default (state = INITIAL, action) => {
  switch(action.type) {
    case REPLACE_POINTS_IN_STORE :
      return action.payload;
    case ADD_POINTS_TO_STORE :
      const points = action.payload;
      return { ...state, ...points };
    case DELETE_POINT_FROM_STORE : {
      const { point_id } = action.payload;
      const newState = {...state};
      delete newState[point_id];
      return newState;
    }
    case UPDATE_POINT_IN_STORE : {
      const point = action.payload;
      return {...state, [point.point_id] : point };
    }
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
