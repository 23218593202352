import { all, put, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';

import { DAY_FORMAT, TIME_FORMAT } from '../constants';

import {
  CREATE_POINT,
  DELETE_POINT,
  EDIT_POINT
} from '../actions/types';

import {
  addPointToStore,
  setStatus,
  deletePointFromStore,
  updatePointInStore,
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( CREATE_POINT, createPoint),
    takeLatest( DELETE_POINT, deletePoint),
    takeLatest( EDIT_POINT, editPoint),
  ])
}


function* createPoint(action) {

  try {

    const { subject_id, behavior_id } = action.payload;

    const state = yield select();

    const today = moment();

    const nextPointID = Math.max(0, ...Object.keys(state.points)) + 1;
    const point    = {
      point_id : nextPointID,
      subject_id,
      behavior_id,
      date : today.format(DAY_FORMAT),
      time : today.format(TIME_FORMAT),
      notes : null,
      behavior : state.behaviors[behavior_id].behavior,
      points : parseInt(state.behaviors[behavior_id].points, 10),
    };

    yield put( addPointToStore( point ) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added points'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not add points'}));
  }

}

function* deletePoint(action) {

  try {
    const { point_id } = action.payload;

    yield put( deletePointFromStore(point_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted point'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete point'}));
  }

}

function* editPoint(action) {

  try {
    const point = action.payload;
    //const res = yield call( () => axios.get(`/api/point/8`) );
    yield put( updatePointInStore(point) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated point'}));
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not update point'}));
  }

}
