import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';

import {
  SELECT_SUBJECT,
  FETCH_BEHAVIORS,
  CREATE_BEHAVIOR,
  EDIT_BEHAVIOR,
  DELETE_BEHAVIOR
} from '../actions/types';

import {
  addBehaviorsToStore,
  addBehaviorToStore,
  updateBehaviorInStore,
  deleteBehaviorFromStore,
  setStatus
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( SELECT_SUBJECT,  fetchBehaviors),
    takeLatest( FETCH_BEHAVIORS, fetchBehaviors),
    takeLatest( CREATE_BEHAVIOR, createBehavior),
    takeLatest( EDIT_BEHAVIOR, editBehavior),
    takeLatest( DELETE_BEHAVIOR, deleteBehavior),
  ])
}

function* fetchBehaviors(action) {
  const state = yield select();

  try {

    const { subject_id } = action.payload;
    const route = `/api/user/${subject_id}/behaviors`;

    const res = yield call( () => axios.get(route) );
    yield put( addBehaviorsToStore(res.data) );
  }
  catch (e){
    yield put( setStatus({type:STATUS.error, message: 'Could not fetch behaviors'}));
  }

}

function* createBehavior(action) {

  try {
    const behavior = action.payload;

    const res = yield call( () => axios.post(`/api/behavior`, behavior ));
    yield put( addBehaviorToStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added behavior'}));
  }
  catch (e){
    yield put( setStatus({type:STATUS.error, message: 'Could not create new behavior'}));
  }

}

function* editBehavior(action) {

  try {
    const { behavior, behavior_id } = action.payload;

    const res = yield call( () => axios.patch(`/api/behavior/${behavior_id}`, behavior ));
    yield put( updateBehaviorInStore(res.data) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated behavior'}));
  }
  catch (e){
    yield put( setStatus({type:STATUS.error, message: 'Could not edit behavior'}));
  }

}

function* deleteBehavior(action) {

  try {
    const behavior_id = action.payload;
    yield call( () => axios.delete(`/api/behavior/${behavior_id}`));
    yield put( deleteBehaviorFromStore(behavior_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted behavior'}));
  }
  catch (e){
    yield put( setStatus({type:STATUS.error, message: 'Could not delete behavior'}));
  }

}
