import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

//import * as d3Scale from 'd3-scale';

import FormattedPoints from './FormattedPoints';
import BehaviorEditor from './BehaviorEditor';
import { TeamLeadComponent, TeamMemberComponent, SubjectComponent } from './AuthenticatedComponent';

import { createPoint, deleteBehavior } from '../actions';
import { getSortedBehaviorsInCategory } from '../selectors';

import CONSTANTS from '../constants';

class Behaviors extends PureComponent {

  state = {
    showBehaviorEditor : false,
  }

  constructor(props) {
    super(props);
    this.handleBehaviorEditorClose  = this.handleBehaviorEditorClose.bind(this);
    this.editBehavior               = this.editBehavior.bind(this);
    this.deleteBehavior             = this.deleteBehavior.bind(this);
  }

  handleBehaviorEditorClose() {
    this.setState(
      {
        behavior_id : undefined,
        showBehaviorEditor:false
      }
    );
  }

  editBehavior(behavior_id) {
    this.setState({
      behavior_id : behavior_id,
      showBehaviorEditor : true,
    })
  }

  deleteBehavior(behavior_id) {
    if (window.confirm('Really delete behavior?')) {
      this.props.deleteBehavior(behavior_id);
    }
  }

  render() {
    const { behaviors, session } = this.props;

    if (! behaviors.length) {
      return <div className='behaviors-empty-container'>No defined behaviors</div>
    }

    /*
    const color = d3Scale.scaleLinear()
      .domain([-100, 0, 100])
      .range(['#FF0000', '#FFFFFF', '#00FF00']);
    */


    return (
      <div className='behaviors-container'>
        { behaviors
            .filter(b => b.visible || session.rank >= CONSTANTS.USER.TEAMLEAD.RANK)
            .map( b => {
              return (
                <div className={`behavior-row ${!b.visible && 'invisible-behavior'}`} key={b.behavior_id}>
                  <div className='behavior-text'>
                    <div className='behavior-name'>{b.behavior}</div>
                    <div className='behavior-description'>{b.description}</div>
                  </div>
                  <div className='buttons'>
                    <TeamLeadComponent>
                      <Button mini variant='fab' aria-label="delete behavior" onClick={(e) => this.editBehavior(b.behavior_id)} tabIndex={-1}>
                        <Icon>edit_icon</Icon>
                      </Button>
                      <Button color='secondary' mini variant='fab' aria-label="delete behavior" onClick={(e) => this.deleteBehavior(b.behavior_id)} tabIndex={-1}>
                        <DeleteIcon/>
                      </Button>
                    </TeamLeadComponent>
                    <TeamMemberComponent>
                      <Button
                        color={ b.points > 0 ? 'primary' : 'secondary' }
                        mini
                        variant='fab'
                        aria-label="record behavior points"
                        onClick={ (e) => {
                          this.props.createPoint(b)
                        }}
                      >
                        {/*<Icon>add_icon</Icon>*/}
                        <FormattedPoints points={b.points}/>
                      </Button>
                    </TeamMemberComponent>
                    <SubjectComponent exact={true}>
                      <FormattedPoints points={b.points}/>
                    </SubjectComponent>
                  </div>
                </div>
              )
            }
          )
        }

        {/*
          XXX This is a stupid hack. The editor reads its props at construction time, which means they're initialized
          before redux is populated. This waits to create/add the component until after the store exists with the values
          we need. It's stupid.

          It'd be better to just always include the component so we don't re-create and mount it, but the reality is That
          this is so low impact we can get away with it for now. Should be refactored, though.
        */}
        { this.state.showBehaviorEditor && <BehaviorEditor
          showEditor={ this.state.showBehaviorEditor }
          handleEditorClose= { this.handleBehaviorEditorClose }
          behavior_id={this.state.behavior_id}
        /> }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    behaviors : getSortedBehaviorsInCategory(state)(state.categories.selected, state.subjects.selected),
    session   : state.session
  }
}

export default connect(mapStateToProps, { createPoint, deleteBehavior })(Behaviors);
