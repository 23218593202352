import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import Subjects       from './Subjects';
import Categories     from './Categories';
import Behaviors      from './Behaviors';
import BehaviorEditor from './BehaviorEditor';

class Dashboard extends PureComponent {
  render() {

    return (
      <Fragment>
        <Subjects
          buttonText={'Add Behavior'}
          editor={BehaviorEditor}
        />
        <Categories />
        <Behaviors />
      </Fragment>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    userRank : state.session.rank
  }
}

export default connect(mapStateToProps)(Dashboard);
