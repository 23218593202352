import { all } from 'redux-saga/effects';

import sessionSaga from './session';
import userSaga from './user';
import behaviorSaga from './behavior';
import categorySaga from './category';
import pointSaga from './point';
import receiptSaga from './receipt';
import rewardSaga from './reward';
import teamSaga from './team';

export default function* rootSaga() {
  yield all([
    sessionSaga(),
    userSaga(),
    behaviorSaga(),
    categorySaga(),
    pointSaga(),
    receiptSaga(),
    rewardSaga(),
    teamSaga()
  ])
}
