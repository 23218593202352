import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import SubjectSelector from './SubjectSelector';

import { createReward, editReward } from '../actions';

const styles = theme => ({
  textField: {
    marginLeft: 0,//theme.spacing.unit,
    marginRight: 0,//theme.spacing.unit,
    width: '100%',
    marginTop : 0,
  },
  dateTimeField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    marginTop : '16px',
    marginBottom : '16px'
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  dialogContent: {
    width : 300,
    marginLeft : 'auto',
    marginRight : 'auto',
    marginTop : 0,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    textAlign : 'center',
  },
  dialogActions : {
    display : 'flex',
    justifyContent : 'space-between'
  },
  formControl: {
    width : '100%',
  },
});

const FIELDS = ['subject_id', 'description', 'points'];

class RewardEditor extends PureComponent {

  state = {
    reward : {
      subject_id  : this.props.reward.subject_id || '',
      description : this.props.reward.description || '',
      points      : this.props.reward.points || 0,
    },

  }

  constructor(props) {
    super(props);
    this.changeValue   = this.changeValue.bind(this);
    this.canSubmit     = this.canSubmit.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
  }

  changeValue(e) {
    return this.changeRewardValue(e.target.id, e.target.value);
  }

  changeRewardValue(key, value) {
    this.setState( {reward : { ...this.state.reward, [key] : value } });
  }

  handleSubmit(e) {
    e.preventDefault(); e.stopPropagation();
    if (this.props.reward_id) {
      this.props.editReward( this.props.reward_id, this.state.reward );
    }
    else {
      this.props.createReward( this.state.reward )
    }

    if (this.props.saveCallback) {
      this.props.saveCallback();
    }

    this.props.handleEditorClose();
  }

  canSubmit() {
    return FIELDS.reduce( (canSubmit, field) => {
      return canSubmit && !!String(this.state.reward[field]).length
    }, true);
  }

  renderTextField({id, label, value, rows = 1, type = 'text'}) {
    return (
      <TextField
        id={id}
        label={label}
        className={this.props.classes.textField}
        type={type}
        value={value}
        margin="normal"
        onChange={ this.changeValue }
        multiline={rows > 1}
        rows={rows}
      />
    )
  }

  render() {

    const { classes } = this.props;
    let { subject_id, description, points } = this.state.reward;

    return (
      <Dialog onClose={this.props.handleEditorClose} aria-labelledby="reward-editor-title" open={this.props.showEditor}>
        <form>
          <DialogTitle id="reward-editor-title">Edit Reward</DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <div>
              <SubjectSelector
                selected={subject_id}
                selectable={false}
                selectSubject={(subject_id) => this.changeRewardValue('subject_id', subject_id)}
              />
            </div>
            <div>
              { this.renderTextField({id : 'description', label : 'Description', value : description}) }
            </div>
            <div>
              { this.renderTextField({id : 'points', label : 'Points', value : points, type : 'number'}) }
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              type='button'
              variant = 'contained'
              color='secondary'
              size="small"
              onClick={this.props.handleEditorClose}>Cancel</Button>
            <Button
              type='submit'
              disabled={!this.canSubmit()}
              variant = 'contained'
              color='primary'
              size="small"
              onClick={this.handleSubmit}>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => {

  const reward = props.reward_id
    ? state.rewards[props.reward_id]
    : { subject_id : state.subjects.selected }
  return {
    reward
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, { createReward, editReward })(RewardEditor)
);
