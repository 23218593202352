import { call, all, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  CREATE_USER,
  EDIT_USER,
  FETCH_USERS,
  DELETE_USER,
  SET_SESSION
} from '../actions/types';

import {
  getSession,
  setStatus,
  replaceUsersInStore,
  deleteUserFromStore,
  updateUserInStore,
  logoutUser,
  selectSubject
} from '../actions';

import { getSubjectsSortedByName } from '../selectors';

import { STATUS} from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( CREATE_USER, createUser),
    takeLatest( EDIT_USER,   editUser),
    takeLatest( FETCH_USERS, fetchUsers),
    takeLatest( SET_SESSION, fetchUsers),
    takeLatest( DELETE_USER, deleteUser),
  ])
}

function* createUser(action) {
  const values = action.payload;
  try {
    const res = yield call(() => axios.post(`/api/user`, values));
    yield put( updateUserInStore( res.data ) );

    const state = yield select();

    if ( !state.subjects.selected || !state.users[state.subjects.selected] ) {
      const subjects = getSubjectsSortedByName(state);

      if (subjects.length) {
        yield(put(selectSubject(subjects[0].user_id)));
      }
    }

    yield put( setStatus({type:STATUS.success, message: 'User created'}));

  }
  catch (e) {
      yield put( setStatus({type:STATUS.error, message: 'Could not sign up'}));
  }
}

function* editUser(action) {
  const { user_id, values } = action.payload;

  try {
    const res = yield call(() => axios.patch(`/api/user/${user_id}`, values));
    const state = yield select();
    yield put( updateUserInStore( res.data ) );
    if (state.session.user_id === user_id) {
      yield put( getSession() );
    }

    if ( !state.subjects.selected || !state.users[state.subjects.selected] ) {
      const subjects = getSubjectsSortedByName(state);

      if (subjects.length) {
        yield(put(selectSubject(subjects[0].user_id)));
      }
    }

    yield put( setStatus({type:STATUS.success, message: 'User updated'}));
  }
  catch (e) {
      yield put( setStatus({type:STATUS.error, message: 'Could not save user'}));
  }
}

function* fetchUsers(action) {
  const state = yield select();

  if (state.session.user_id) {
    try {
      const res = yield call(() => axios.get(`/api/users`));
      yield put( replaceUsersInStore(res.data));

      const state = yield select();

      if ( !state.subjects.selected || !state.users[state.subjects.selected] ) {
        const subjects = getSubjectsSortedByName(state);

        if (subjects.length) {
          yield(put(selectSubject(subjects[0].user_id)));
        }
      }
    }
    catch (e) {
      yield put( setStatus({type:STATUS.error, message: 'Could not fetch users'}));
    }
  }
}

function* deleteUser(action) {
  try {
    const user_id = action.payload;
    yield call(() => axios.delete(`/api/user/${user_id}`));
    let state = yield select();

    yield put( setStatus({type:STATUS.success, message: 'Deleted user'}));
    yield put( deleteUserFromStore(user_id));
    if (state.session.user_id === user_id) {
      yield put( logoutUser() );
    }

    // okay, there is some nasty stuff that happens here. If we delete the
    // currently selected subject, we must re-select the next one.
    if (user_id === state.subjects.selected) {
      state = yield select();
      const subjects = getSubjectsSortedByName(state);

      if (subjects.length) {
        yield(put(selectSubject(subjects[0].user_id)));
      }
      else {
        yield(put(selectSubject(undefined)));
      }
    }
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not delete user'}));
  }
}
