import {
  FETCH_BEHAVIORS,
  REPLACE_BEHAVIORS_IN_STORE,
  ADD_BEHAVIORS_TO_STORE,
  FETCH_BEHAVIORS_IN_CATEGORY,
  CREATE_BEHAVIOR,
  EDIT_BEHAVIOR,
  UPDATE_BEHAVIOR_IN_STORE,
  DELETE_BEHAVIOR,
  DELETE_BEHAVIOR_FROM_STORE
} from './types';

export const fetchBehaviors = (subject_id) => {
  return {
    type    : FETCH_BEHAVIORS,
    payload : { subject_id }
  }
}

export const fetchBehaviorsInCategory = (category_id) => {
  return {
    type    : FETCH_BEHAVIORS_IN_CATEGORY,
    payload : { category_id }
  }
}

export const replaceBehaviorsInStore = (behaviors) => {
  return {
    type    : REPLACE_BEHAVIORS_IN_STORE,
    payload : behaviors
  }
}

export const addBehaviorsToStore = (behaviors) => {
  return {
    type    : ADD_BEHAVIORS_TO_STORE,
    payload : behaviors
  }
}

export const createBehavior = (behavior) => {
  return {
    type    : CREATE_BEHAVIOR,
    payload : behavior
  }
}

export const addBehaviorToStore = (behavior) => {
  return {
    type    : ADD_BEHAVIORS_TO_STORE,
    payload : { [behavior.behavior_id] : behavior }
  }
}

export const editBehavior = (behavior_id, behavior) => {
  return {
    type    : EDIT_BEHAVIOR,
    payload : { behavior_id, behavior }
  }
}

export const updateBehaviorInStore = (behavior) => {
  return {
    type    : UPDATE_BEHAVIOR_IN_STORE,
    payload : behavior
  }
}

export const deleteBehavior = (behavior_id) => {
  return {
    type    : DELETE_BEHAVIOR,
    payload : behavior_id
  }
}

export const deleteBehaviorFromStore = (behavior_id) => {
  return {
    type    : DELETE_BEHAVIOR_FROM_STORE,
    payload : behavior_id
  }
}
