import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';

import UserEditor from './UserEditor';

import logo from '../logo.svg';

import { logoutUser } from '../actions';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  name : {
    align : 'right'
  }
};

class NavBar extends PureComponent {

  state = {
    showUserEditor : false
  }

  constructor(props) {
    super(props);

    this.handleUserEditorClose  = this.handleUserEditorClose.bind(this);
    this.openUserEditor         = this.openUserEditor.bind(this);
  }

  openUserEditor() {
    this.setState({showUserEditor : true});
  }

  handleUserEditorClose() {
    this.setState({showUserEditor:false});
  }

  renderUserEditorDialog() {
    return this.state.showUserEditor &&
      <UserEditor
        handleUserEditorClose={this.handleUserEditorClose}
        showUserEditor={this.state.showUserEditor}
        user_id={this.props.session.user_id} />
    ;
  }

  render() {
    const { classes, session, logoutUser } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <a href = '/'><img src={logo} className="App-logo" alt="logo" /></a>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Kighzen
            </Typography>
            <Typography variant="h6" color="inherit" className={classes.name}>
              <span className='user-name' onClick={this.openUserEditor}>
                { session.name }
              </span>
            </Typography>
            { session.user_id && <IconButton className={classes.logoutButton} color="inherit" aria-label="Logout" onClick={logoutUser}>
              <Icon className={classNames(classes.icon, 'fa fa-sign-out')} />
            </IconButton> }
            { !session.user_id &&
              <Fragment>
                <Button
                  type='submit'
                  variant='contained'
                  size='small'
                  color='secondary'
                  className='demo-button'
                  onClick={ () => window.location = '/dashboard?demo=1' }>Try a demo</Button>
                <Button
                  type='submit'
                  variant = 'contained'
                  size="small"
                  onClick={() => this.props.history.push('/session')}>Log in</Button>
              </Fragment>
            }
          </Toolbar>
        </AppBar>
        { this.renderUserEditorDialog() }
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session : state.session || {}
  }
}

export default withRouter(
  connect(mapStateToProps, { logoutUser })(withStyles(styles)(NavBar))
);
