import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { loginUser, createUser, editUser, deleteUser, getSession } from '../actions';
import { USER } from '../constants';

const styles = theme => ({
  card: {
    width : 300,
    marginTop : '50px',
    marginLeft : 'auto',
    marginRight : 'auto'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    textAlign : 'center',
  },
  cardActions : {
    display : 'flex',
    justifyContent : 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class Session extends PureComponent {

  static propTypes = {
    classes : PropTypes.object.isRequired
  }

  state = {
    newUser : !!this.props.user,
    user    : {
      username : '',
      password : '',
      error    : false
    }
  }

  constructor(props) {
    super(props);

    this.updateState   = this.updateState.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);

  }

  updateState(e) {
    const newValues = {[e.target.id]: e.target.value};
    this.setState( { user : { ...this.state.user, ...newValues} } );
  }

  canSubmit() {
    if (this.state.newUser) {
      return this.state.user.username.length
          && (this.props.user || this.state.user.password.length)
          && !this.state.user.error
          && this.state.user.name.length
          && this.state.user.expected_calories;
    }
    else {
      return this.state.user.username.length && this.state.user.password.length;
    }
  }

  handleSubmit(e) {
    e.preventDefault(); e.stopPropagation();
    this.props.loginUser(this.state.user);
  }

  componentDidMount() {
    this.props.getSession();
  }

  render() {

    const { classes, session } = this.props;

    if (session.user_id) {
      return <Redirect to={ session.rank === USER.SUBJECT.RANK ? '/rewards' : '/dashboard' } />;
    }

    return (
      <div className='session-block'>
        <form>
          <Typography variant='h4' className={classes.header}>
            Welcome to Kighzen. Please log in.
          </Typography>
          <Card className={classes.card}>
            <CardContent>
              <div>
                <TextField
                  id="username"
                  label="Username"
                  placeholder="TEAMNAME/username"
                  className={classes.textField}
                  margin="normal"
                  onChange={ this.updateState }
                  value={this.props.username}
                />
              </div>
              <div>
                <TextField
                  id="password"
                  label="Password"
                  type='password'
                  className={classes.textField}
                  margin="normal"
                  onChange={ this.updateState }
                  value={this.props.password}
                  autoComplete="yes"
                />
              </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                type='submit'
                disabled={!this.canSubmit()}
                variant = 'contained'
                color='primary'
                size="small"
                onClick={this.handleSubmit}>Log in</Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ( {session, status} ) => {
  return { session }
}

export default withStyles(styles)(
  connect(mapStateToProps, {loginUser, createUser, editUser, deleteUser, getSession})(Session)
);
