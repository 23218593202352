import {
  LOGOUT_USER,
  SELECT_CATEGORY,
  REPLACE_CATEGORIES_IN_STORE,

  ADD_CATEGORY_TO_STORE,
  UPDATE_CATEGORY_IN_STORE,

  DELETE_CATEGORY_FROM_STORE

} from '../actions/types';

import { ALL_CATEGORIES } from '../constants';

const INITIAL = { all : {}, selected : ALL_CATEGORIES };

export default (state = INITIAL, action) => {
  switch(action.type) {
    case SELECT_CATEGORY : {
      const { category_id } = action.payload;
      const allCategories = state.all;

      if ( category_id === ALL_CATEGORIES || allCategories[category_id] !== undefined ) {
        return {...state, selected : category_id };
      }

      return state;
    }
    case REPLACE_CATEGORIES_IN_STORE : {
      const all = action.payload;
      return { all, selected : ALL_CATEGORIES };
    }
    case ADD_CATEGORY_TO_STORE :
    case UPDATE_CATEGORY_IN_STORE :
      const category = action.payload;
      return {...state, [category.category_id] : category };
    case DELETE_CATEGORY_FROM_STORE : {
      const category_id = action.payload;
      let newState = {...state};
      delete newState[category_id];
      return newState;
    }
    case LOGOUT_USER :
      return INITIAL;
    default :
      return state;
  }
};
