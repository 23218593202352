import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';

import {
  SELECT_SUBJECT,
  FETCH_BEHAVIORS,
  CREATE_BEHAVIOR,
  EDIT_BEHAVIOR,
  DELETE_BEHAVIOR
} from '../actions/types';

import {
  replaceBehaviorsInStore,
  addBehaviorToStore,
  updateBehaviorInStore,
  deleteBehaviorFromStore,
  setStatus
} from '../actions';

import { STATUS } from '../constants';

export default function* transactionSaga() {
  yield all([
    takeLatest( SELECT_SUBJECT,  fetchBehaviors),
    takeLatest( FETCH_BEHAVIORS, fetchBehaviors),
    takeLatest( CREATE_BEHAVIOR, createBehavior),
    takeLatest( EDIT_BEHAVIOR, editBehavior),
    takeLatest( DELETE_BEHAVIOR, deleteBehavior),
  ])
}

const demoBehaviors = {
  1 : {
    category_ids : [],
    subject_id : 2,
    behavior : "uses a kind voice",
    "description" : "",
    points : 5,
    color : "",
    visible : 1,
    behavior_id : 1,
  },
}

function* fetchBehaviors(action) {

  try {
    yield put( replaceBehaviorsInStore(demoBehaviors) );
  }
  catch (e) {
    yield put( setStatus({type:STATUS.error, message: 'Could not behaviors rewards'}));
  }

}

function* createBehavior(action) {

  try {

    const state = yield select();

    const nextBehaviorID = Math.max(0, ...Object.keys(state.behaviors)) + 1;
    const behavior       = { category_ids : [], ...action.payload, behavior_id : nextBehaviorID };

    yield put( addBehaviorToStore(behavior) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully added behavior'}));
  }
  catch (e){
    yield put( setStatus({type:STATUS.error, message: 'Could not create new behavior'}));
  }

}

function* editBehavior(action) {

  try {
    const { behavior, behavior_id } = action.payload;

    yield put( updateBehaviorInStore(behavior) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully updated behavior'}));
  }
  catch (e){
    yield put( setStatus({type:STATUS.error, message: 'Could not edit behavior'}));
  }

}

function* deleteBehavior(action) {

  try {
    const behavior_id = action.payload;
    yield put( deleteBehaviorFromStore(behavior_id) );
    yield put( setStatus({type:STATUS.success, message: 'Successfully deleted behavior'}));
  }
  catch (e){
    yield put( setStatus({type:STATUS.error, message: 'Could not delete behavior'}));
  }

}
