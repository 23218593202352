import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { createPoint, editPoint } from '../actions';

const styles = theme => ({
  textField: {
    marginLeft: 0,//theme.spacing.unit,
    marginRight: 0,//theme.spacing.unit,
    width: '100%',
    marginTop : 0,
  },
  dateTimeField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    marginTop : '16px',
    marginBottom : '16px'
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  dialogContent: {
    width : 300,
    marginLeft : 'auto',
    marginRight : 'auto',
    marginTop : 0,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header : {
    textAlign : 'center',
  },
  dialogActions : {
    display : 'flex',
    justifyContent : 'space-between'
  },
  formControl: {
    width : '100%',
  },
});

class PointEditor extends PureComponent {

  state = {
    point : {
      notes : this.props.point.notes || '',
    }
  }

  constructor(props) {
    super(props);
    this.changeValue   = this.changeValue.bind(this);
    this.canSubmit     = this.canSubmit.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);

  }

  changeValue(e) {
    return this.changePointValue(e.target.id, e.target.value);
  }

  changePointValue(key, value) {
    this.setState( {point : { ...this.state.point, [key] : value } });
  }

  handleSubmit(e) {
    e.preventDefault(); e.stopPropagation();
    this.props.editPoint( this.props.point_id, this.state.point );

    if (this.props.saveCallback) {
      this.props.saveCallback();
    }

    this.props.handlePointEditorClose();
  }

  canSubmit() {
    return true;
  }

  renderTextField({id, label, value, rows = 1, type = 'text'}) {
    return (
      <TextField
        id={id}
        label={label}
        className={this.props.classes.textField}
        type={type}
        value={value}
        margin="normal"
        onChange={ this.changeValue }
        multiline={rows > 1}
        rows={rows}
      />
    )
  }

  render() {

    const { classes } = this.props;
    let { notes } = this.state.point;

    return (
      <Dialog onClose={this.props.handlePointEditorClose} aria-labelledby="point-editor-title" open={this.props.showPointEditor}>
        <form>
          <DialogTitle id="point-editor-title">Edit Point</DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <div>
              { this.renderTextField({id : 'notes', label : 'Notes', value : notes, rows : 3}) }
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              type='button'
              variant = 'contained'
              color='secondary'
              size="small"
              onClick={this.props.handlePointEditorClose}>Cancel</Button>
            <Button
              type='submit'
              disabled={!this.canSubmit()}
              variant = 'contained'
              color='primary'
              size="small"
              onClick={this.handleSubmit}>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    point : state.points[props.point_id]
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, { createPoint, editPoint })(PointEditor)
);
